import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { EyeOffSharp, EyeSharp, LockClosedSharp, LogOutOutline, MailSharp, PersonCircleSharp, ShieldCheckmarkOutline, ShieldSharp } from 'react-ionicons'
import { useAuth } from '../authentication/userAuth';
import { ThemeContext } from '../context';

const Settings = () => {
    const { theme } = useContext(ThemeContext);
    const { user, mysettings, settings, logout } = useAuth();
    const navigate = useNavigate();
    const [open, setOpen] = useState(settings.hideBalance);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const toggleShowBalance = () => {
        setOpen(!open);
        // update settings local storage
        mysettings({
            hideBalance: !(settings.hideBalance)
        });
    }

    const logOut = () => {
        logout();
        navigate("/", { replace: true });
    }

    return (
        <>
            <div className="content d-col scroll-y">
                <div className='card d-row-col' style={{ margin: "0 auto" }}>
                    <div className='w-full'>
                        <div className='d-col centered my-4'>
                            {
                                (user?.profilephoto === 'human.png') ? (
                                    <div className='circle-80-full pos-rel centered bg-grey mx-2'>
                                        <h3 className='text-black text-bold'>{user?.fullname.split(' ')[0][0]}{user?.fullname.split(' ')[1][0]}</h3>
                                    </div>
                                ) : (
                                    <img src={`https://copywavex.com/uploads/${user?.profilephoto}`} className="img-cover circle-80-full pos-rel centered bg-grey mx-2" alt={user.fullname} />
                                )
                            }
                            <p className='text-center mt-2'>Name: {user.fullname}</p>
                            <p className='text-center mt-2'>Userid: {user?.owner_id}</p>
                            <p className='text-center mt-2'>Referal code: {`https://copywavex.com/register/${user?.owner_id}`}</p>
                        </div>
                        <div className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full justify-between'>
                            <p>
                                Show / hide balance shown at the top of the app
                            </p>
                            <Link to='#' onClick={() => toggleShowBalance()}>
                                {
                                    open ? (
                                        <EyeOffSharp />
                                    ) : (
                                        <EyeSharp />
                                    )
                                }
                            </Link>
                        </div>
                        <Link className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full justify-between' to='/user/verifications' >
                            <p>
                                Verify and proof that you are real human
                            </p>
                            {
                                (user.idVerifyStatus === '1') ? (
                                    <ShieldCheckmarkOutline color={'#00ff00'} />
                                ) : (
                                    <ShieldSharp />
                                )
                            }
                        </Link>
                        <Link className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full justify-between' to='/user/updates/email' >
                            <p>
                                Click here to update account email address
                            </p>
                            <MailSharp />
                        </Link>
                        <Link className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full justify-between' to='/user/updates/password' >
                            <p>
                                Click here to update account password
                            </p>
                            <LockClosedSharp />
                        </Link>
                        <Link className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full justify-between' to='/user/updates/profile-photo' >
                            <p>
                                Click here to update account profile photo
                            </p>
                            <PersonCircleSharp />
                        </Link>

                        <button className={(theme === 'light')?"btn btn-full hr-row w-full spacex-2 bg-blue-light text-white":"btn btn-full hr-row w-full spacex-2 bg-blue text-white"} onClick={logOut} >
                            <LogOutOutline color={"#fff"}/>
                            <span>SignOut</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings
