import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../authentication/userAuth';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';

const KycVerification = () => {
    const { user } = useAuth();
    const navigate = useNavigate();
    const img = useRef(null);
    const canvas = useRef(null);
    const video = useRef(null);

    const [mediaStream, setMediaStream] = useState(null);
    const [capturedImage, setCapturedImage] = useState(null);
    const [isLoading, setIsloading] = useState(false);
    const [permission, setPermission] = useState(false);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        try {
            const kycImage = capturedImage;
            const formData = new FormData();
            formData.append('image', kycImage);
            formData.append("uploader_id", user.owner_id);

            const resp = await fetch("https://copywavex.com/api/user/kyc/upload", {
                method: 'POST',
                body: formData
            });

            const response = await resp.json();

            if (response.status === 200) {
                toast.success("Upload successfull");
                setIsloading(false);
                setTimeout(() => {
                    navigate('/user/verifications');
                }, 2000);
            } else {
                toast.error(response.message);
                setIsloading(false);
            }
        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    // Function to stop the webcam
    const stopWebcam = () => {
        if (mediaStream) {
            mediaStream.getTracks().forEach((track) => {
                track.stop();
            });
            setMediaStream(null);
        }
    };

    const captureImage = () => {
        if (canvas.current && video.current) {
            const videoRef = video.current;
            const canvasRef = canvas.current;
            const context = canvasRef.getContext("2d");

            context.drawImage(videoRef, 0, 0, canvasRef.width, canvasRef.height);

            // Get image data URL from canvas
            const imageDataUrl = canvasRef.toDataURL("image/png");

            // Set the captured image
            setCapturedImage(imageDataUrl);

            // Stop the webcam
            stopWebcam();
        }
    }

    const take_retake = () => {
        setCapturedImage(null);
        navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
                facingMode: 'user'
            }
        }).then((stream) => {
            video.current.srcObject = stream;
            setMediaStream(stream);
        }).catch((err) => { console.log(err) });
    }

    useEffect(() => {
        if (navigator.mediaDevices) {
            setPermission(true)
            navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    facingMode: 'user'
                }

            }).then((stream) => {
                video.current.srcObject = stream;
                setMediaStream(stream);
            }).catch((err) => { toast.error(err.message) });
        } else {
            toast.error("Enable camera permission to continue");
            setPermission(false);
        }
        return () => stopWebcam();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <>
            <div className="content d-col scroll-y">
                <div className="card bg-grey flex-1 d-col" style={{ margin: "0 auto" }}>
                    <div>
                        <h2 className="text-center">KYC</h2><br />
                        <p style={{ "textAlign": "center" }}>
                            Stay in a well lighted environment
                        </p>
                    </div>
                    {
                        permission ? (
                            <>
                                <div>
                                    <form encType="multipart/form-data" onSubmit={handleSubmit} className="centered d-col spacex-2 flex-1 mt-4">
                                        {
                                            capturedImage ? (
                                                <>
                                                    <img
                                                        src={capturedImage}
                                                        ref={img} alt='kyc'
                                                        style={{
                                                            height: "300px",
                                                            width: "300px",
                                                            margin: "0 auto",
                                                            objectFit: "fill",
                                                            aspectRatio: '1',
                                                            borderRadius: "50%"
                                                        }}
                                                    />

                                                    {
                                                        isLoading ? (
                                                            <div className='flex justify-center items-center'>
                                                                <InfinitySpin
                                                                    visible={true}
                                                                    width="200"
                                                                    color="#205fb0"
                                                                    ariaLabel="infinity-spin-loading"
                                                                />
                                                            </div>

                                                        ) : (
                                                            <>
                                                                <div className='my-4 btn btn-full text-center' onClick={() => take_retake()}>Retake</div>
                                                                <button type='submit' className='my-4 btn btn-full bg-blue text-white text-center'>Upload</button>
                                                            </>
                                                        )
                                                    }

                                                </>
                                            ) : (
                                                <>
                                                    <canvas style={{ display: "none" }} className='shadow' ref={canvas}></canvas>
                                                    <video ref={video} className='my-2' height={300} width={300} autoPlay muted style={{ margin: "0 auto", objectFit: "cover", borderRadius: "50%" }} />
                                                    <div className='circle-50-full bg-blue my-4' onClick={() => captureImage()}></div>
                                                </>
                                            )
                                        }
                                    </form>
                                    <ToastContainer />
                                </div>
                            </>
                        ) : (
                            <div className='centered d-col spacex-2 flex-1'>
                                <h2 className="text-center">PERMISSION DENIED</h2><br />
                                <p style={{ "textAlign": "center" }}>
                                    Enable camera permission to continue (Once enabled reload page)
                                </p>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default KycVerification