import React, { useContext, useEffect, useState } from 'react'
import { KeyOutline, MailOutline } from 'react-ionicons'
import { InfinitySpin } from 'react-loader-spinner'
import { toast, ToastContainer } from 'react-toastify'
import { useAuth } from '../authentication/userAuth'
import { Link, useNavigate } from 'react-router-dom'
import Header from '../components/header'
import { ThemeContext } from '../context'

const Login = () => {
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [isLoading, setIsloading] = useState(false);

    const { login } = useAuth();

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);
        try {
            let data = {
                password,
                email
            }

            const resp = await fetch('https://copywavex.com/api/user/login', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const response = await resp.json();

            if (response.status === 200) {
                await login(response.user);
                setTimeout(() => {
                    setIsloading(false);
                    navigate("/user");
                }, 2000);

            } else {
                // setError(response.message);
                setIsloading(false);
                toast.error(response.message);
            }

        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
            //   setError(err.message)
        }

    }
    return (
        <div className="r-app remove-tidio">
            <Header />
            <div className="content d-col px-2">
                <div className="card bg-grey curve-5">
                    <h2 className='text-center'>Login to your account</h2><br />
                    <p className='text-center'>You can sign in to your account using email or username</p>
                    <div className="cardd-panel center">
                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <MailOutline />
                                    <input
                                        type="email" id="email"
                                        name="email" autoComplete="new-email"
                                        required="" placeholder='Enter new email'
                                        className='form-control'
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <KeyOutline />
                                    <input
                                        type="password" id="password"
                                        name="password" autoComplete="new-email"
                                        required placeholder='Enter account password'
                                        className='form-control'
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='text-right my-2'>
                                <Link to="/forgot-password" className="fw-bold text-red">forgot password?</Link>
                            </div>
                            {
                                isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>

                                ) : (
                                    <div className="input-field">
                                        <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Login</button>
                                    </div>
                                )
                            }

                            <div className="col-12 mt-4">
                                <p className="text-center">
                                    Dont have an account? <Link to="/register" className="fw-bold text-green">Register</Link>
                                </p>
                            </div>
                        </form>
                        <ToastContainer />
                        <br /><br />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
