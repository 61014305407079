import React, { useContext, useEffect, useState } from 'react'
import { KeyOutline } from 'react-ionicons';
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../authentication/userAuth';
import { ThemeContext } from '../../context';

const UpdatePassword = () => {
    const { theme } = useContext(ThemeContext);
    const { user } = useAuth();

    const [passwordOld, setOldPassword] = useState('');
    const [passwordNew, setNewPassword] = useState('');
    const [passwordConfirm, setConfirmPassword] = useState('');
    // const [error, setError] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        if (passwordNew === passwordConfirm) {
            try {
                let data = {
                    NewPassword: passwordNew,
                    oldPassword: passwordOld,
                    id: user.owner_id
                }

                const resp = await fetch('https://copywavex.com/api/user/account/update/password', {
                    method: "POST",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(data)
                });
                const response = await resp.json();

                if (response.status === 200) {
                    toast(response.message);
                    setIsloading(false);
                    setTimeout(() => {
                        navigate('/user/profile');
                    }, 2000);
                } else {
                    // setError(response.message)
                    toast(response.message)
                    toast(response.message);
                }

            } catch (err) {
                setIsloading(false)
                toast(err.message);
                // setError(err.message)
            }
        } else {
            setIsloading(false)
            toast.error("Passsword does not match")
            // setError("Passsword does not match")
        }

    }
    return (
        <>
            <div className="content d-col">
                <div className="card bg-grey">
                    <h2 className='text-center'>UPDATE PASSWORD</h2><br />
                    <div className="cardd-panel center">
                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <KeyOutline />
                                    <input
                                        type="password" id="password" name="password"
                                        autoComplete="new-password" required="" value={passwordOld}
                                        className='form-control' placeholder='Old password'
                                        onChange={(e) => setOldPassword(e.target.value)} aria-autocomplete="list" />
                                </div>
                            </div>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <KeyOutline />
                                    <input
                                        type="password" id="new_password" name="new_password"
                                        className='form-control' placeholder='New password'
                                        autoComplete="new-password" required="" value={passwordNew}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <KeyOutline />
                                    <input
                                        type="password" id="confirm_new_password" className='form-control'
                                        name="confirm_new_password" autoComplete="new-password"
                                        required="" value={passwordConfirm} placeholder='Confirm new password'
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            {
                                isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>

                                ) : (
                                    <div className="input-field">
                                        <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Update</button>
                                    </div>
                                )
                            }

                        </form>

                        <ToastContainer />
                        <br /><br />

                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePassword
