import React from 'react'
import { Link } from 'react-router-dom'

const BuyCrypto = () => {
    return (
        <div className="content d-col scroll-y bg-grey">
            <div className='flex-1 centered'>
                <div className="h-full w-full d-col justify-center">
                    <div className="card"><br />
                        <div className="roww">
                            <div className="col s12">
                                <h2>BUY CRYPTO</h2>
                                <p className="container">Buy bitcoin, ethereum, and other crypto currencies for account funding from third parties</p><br /><br />
                                <ul className="collection">
                                    <Link to="https://gemini.com/" target="_blank">
                                        <li className="collection-item btn btn-full">Gemini</li>
                                    </Link><br /><br />
                                    <Link to="https://coinbase.com/" target="_blank">
                                        <li className="collection-item btn btn-full">Coinbase</li>
                                    </Link><br /><br />
                                    <Link to="https://crypto.com/" target="_blank">
                                        <li className="collection-item btn btn-full">Crypto.com</li>
                                    </Link><br /><br />
                                    <Link to="https://bitcoin.com/" target="_blank">
                                        <li className="collection-item btn btn-full">Bitcoin.com</li>
                                    </Link>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BuyCrypto
