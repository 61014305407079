import React, { useContext, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../authentication/userAuth';
import { useNavigate } from 'react-router-dom';
import { InfinitySpin } from 'react-loader-spinner';
import { MailOutline } from 'react-ionicons';
import { ThemeContext } from '../../context';

const EmailVerification = () => {
    const { theme } = useContext(ThemeContext);
    const { user } = useAuth();
    const navigate = useNavigate();

    const [isLoading, setIsloading] = useState(false);
    const [isLoading2, setIsloading2] = useState(false);

    const [pin, setPin] = useState('');

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true)

        try {
            let data = {
                code: pin,
                email: user.email
            }

            const resp = await fetch('https://copywavex.com/api/user/verification/email', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const response = await resp.json();

            if (response.status === 200) {
                toast.success(response.message);
                setIsloading(false)
                setTimeout(() => {
                    navigate('/user/verifications/id');
                }, 2200)
            } else {
                setTimeout(() => {
                    toast.error(response.message)
                }, 2100)
            }

        } catch (err) {
            setIsloading(false)
            toast.error(err.message)
        }
    }

    const handleResend = async (e) => {
        e.preventDefault();

        setIsloading2(true);
        try {
            let data = {
                email: user.email
            }

            const resp = await fetch('https://copywavex.com/api/user/verification/resend-email', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const response = await resp.json();

            if (response.status === 200) {
                setIsloading2(false)
                toast.success(response.message)
            } else {
                toast.error(response.message)
            }

        } catch (err) {
            setIsloading2(false);
            toast.error(err.message)
        }
    }

    // const goBack = () => {
    //     logout();
    //     navigate("/login", { replace: true });
    // }
    return (
        <>
            <div className="content d-col scroll-y">
                <div className='flex-1 centered'>
                    <div className="h-full w-full d-col">
                        <div className="card bg-grey flex-1 d-col" style={{ margin: "0 auto" }}><br />
                            <h2 className='text-center'>Email verification</h2><br />
                            <p className='text-center'>An email containing your 5-digit PIN has been sent to your email If you have not received it in a minute or two, click ‘Resend’.</p><br />
                            <form autoComplete="off" onSubmit={handleSubmit} className='px-2'>
                                <div className="">
                                    <div className='input-field'>
                                        <label htmlFor="amount">Pin</label>
                                        <div className='input-with-icon'>
                                            <span className="prefix">
                                                <MailOutline />
                                            </span>
                                            <input
                                                type="number"
                                                id="pin"
                                                name="pin"
                                                className="form-control"
                                                onChange={(e) => setPin(e.target.value)}
                                                value={pin}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                                <br /><br />

                                {
                                    isLoading ? (
                                        <div className='flex justify-center items-center'>
                                            <InfinitySpin
                                                visible={true}
                                                width="200"
                                                color="#205fb0"
                                                ariaLabel="infinity-spin-loading"
                                            />
                                        </div>

                                    ) : (
                                        <>
                                            <div className='input-field'>
                                                <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>VERIFY EMAIL</button>
                                            </div><br /><br />
                                        </>
                                    )
                                }
                            </form>
                            <form autoComplete="off" onSubmit={handleResend} className='px-2'>
                                <div className="">
                                    <div className='input-field'>
                                        <input
                                            type="hidden"
                                            id="email"
                                            name="email"
                                            autoComplete="new-email"
                                            required
                                            readOnly
                                            defaultValue={user.email} />
                                    </div>
                                </div>
                                <br /><br />

                                {
                                    isLoading2 ? (
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    ) : (
                                        <div className='input-field'>
                                            <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>RESEND PIN</button>
                                        </div>
                                    )
                                }
                            </form>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EmailVerification
