import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AddOutline } from 'react-ionicons';

const DepositSingle = () => {
  const navigate = useNavigate();

  const [deposit, setDeposit] = useState({});
  const { id } = useParams();

  useEffect(() => {
    document.body.classList.add('remove-tidio');
}, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://copywavex.com/api/user/deposits/fund/single`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id })
      })
      const response = await data.json();
      if (response.status === 200) {
        setDeposit(response.deposit);
      } else {
        navigate('/user/deposits/list');
      }
    }
    fetchData();
  }, [navigate, id])
  return (
    <>
      <div className="content d-col">
        <div className='flex-1'>
          <div className="h-full w-full d-col justify-center px-2">
            <div className="card bg-grey"><br />
              <h2 className='text-center'>Transaction Details</h2><br />
              <p>
                {
                  (deposit.status === "Pending") &&(
                    <Link to={`/user/deposits/upload-proof/${deposit.id}`}>Upload Payment Proof</Link>
                  )
                }
              </p>
              <div className="w-full">

                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Date</span>
                  <span>{deposit.createdAt}</span>
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Deposit Type</span>
                  <span>{deposit.paytype}</span>
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Reason</span>
                  <span>{deposit.reason}</span>
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Status</span>
                  <span className='text-green'>{deposit.status}</span>
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Amount</span>
                  <span>${deposit.amount}</span>
                </div>
                <div className="fixed-action-btnn">
                    <Link title="New Deposit" className="floating bg-blue centered circle-30-full" id="btn" to="/user/deposits/fund/step1">
                        <AddOutline color={"#fff"}/>
                    </Link>
                </div >
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default DepositSingle
