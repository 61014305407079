import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { useAuth } from '../authentication/userAuth';
import { Link } from 'react-router-dom';
// import { traderss } from '../database';
import { SearchOutline } from 'react-ionicons';
import BottomNav from './bottomNav';

const CopyTraders = () => {
    const { user } = useAuth();
    const srch = useRef(null);
    const [traders, setTraders] = useState([]);
    const [SearchTraders, setSearchTraders] = useState([]);
    const [up, setUp] = useState(0);
    const [openSearch, setOpenSearch] = useState(false);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`https://copywavex.com/api/user/traders/all`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ userid: user.owner_id })
            });
            const resp = await response.json();
            setTraders(resp.traders);
        }
        fetchData();
        // setTraders(traderss);
    }, [up, user.owner_id]);

    const TradeCopyRequest = async (e) => {
        e.preventDefault();

        let copy = {
            trader_id: e.target.dataset.trader,
            copier_id: user.owner_id
        }

        const response = await fetch(`https://copywavex.com/api/user/traders/copy`, {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(copy)
        });

        const resp = await response.json();
        if (resp.status === 200) {
            setUp(up + 1);
        } else {
            toast.error(resp.message)
        }
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        setOpenSearch(true);
        if (e.target.value.length > 0) {
            let search = traders.filter(trader => trader.name.toLowerCase().includes(e.target.value.toLocaleLowerCase()));
            setSearchTraders(search);
        } else {
            setSearchTraders([]);
        }
    }
    return (
        <>
            <div className="content d-col pos-rel scroll-y mb-64">
                <div className='flex-1'>
                    <div className='card' style={{ marginTop: 0, marginBottom: 0 }}>
                        <div className='w-full hr-row spacex-2 bg-white curve-5' style={{ height: "40px" }}>
                            <div className="input-with-icon">
                                <label>
                                    <SearchOutline />
                                </label>
                                <input
                                    type="search"
                                    ref={srch}
                                    id="autocomplete-input"
                                    className='form-control'
                                    style={{ padding: "0 10px" }}
                                    placeholder="Search Traders"
                                    onChange={handleSearch}
                                />
                            </div>
                            <div className='pointer px-2' onClick={() => {
                                srch.current.value = '';
                                setOpenSearch(false)
                            }}>Cancel</div>
                        </div>
                    </div>
                    {
                        openSearch && (
                            <div className='searchTradersResult pos-fixed bg-grey w-full h-full px-2 scroll-y' style={{ top: "140px", left: "0", zIndex: 2, height: "100vh" }}>
                                {
                                    SearchTraders?.map((trader, index) => (
                                        <Link className='d-block card bg-white shadow my-2 curve-5' key={index}>
                                            <div className='hr-row my-2'>
                                                <div className='w-full hr-row'>
                                                    <img src={`https://copywavex.com/uploads/${trader.image}`} alt={trader.name} className='d-block circle-40-full pos-rel' />
                                                    <div className='ml-2 w-full'>
                                                        <div className='hr-row spacex-2'>
                                                            <h3>{trader.name}</h3>
                                                            {(trader.verified === "1") && (<img src='assets/twitter.jpeg' style={{width:"15px"}}  alt='te'/>)}
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    (trader.copier.length > 0) ? (
                                                        trader.copier.map((copier, index) => (
                                                            (copier.accepted === "1") ? (
                                                                <div className='ml-2' key={index} >
                                                                    <button className='btn px-4 bg-green tex-white'>Accepted</button>
                                                                </div>
                                                            ) : (
                                                                <div className='ml-2' key={index} >
                                                                    <button className='btn px-4 bg-blue text-white'>Requested</button>
                                                                </div>
                                                            )
                                                        ))
                                                    ) : (
                                                        <div className='ml-2'>
                                                            <button className='btn px-4' data-trader={trader.id} onClick={TradeCopyRequest}>copy</button>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className='hr-row justify-between'>
                                                <div className='d-col'>
                                                    <div className='my-2'>
                                                        <p>ROI</p>
                                                        <p className='text-green'>+{trader.roi}%</p>
                                                    </div>
                                                </div>

                                                <div className='d-col'>
                                                    <div className='my-2'>
                                                        <p>Minimum</p>
                                                        <p>+{trader.minimum}</p>
                                                    </div>
                                                </div>

                                                <div className='d-col'>
                                                    <div className=' my-2'>
                                                        <p>Win Rate</p>
                                                        <p>+{trader.win_percent}%</p>
                                                    </div>
                                                </div>

                                                <div className='d-col text-right'>
                                                    <div className='my-2'>
                                                        <p>Stability Index</p>
                                                        <p>{trader.stabilityIndex}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))
                                }
                            </div>
                        )
                    }

                    <div className="h-full py-2 px-2 scroll-y">
                        {
                            traders?.map((trader, index) => (
                                <div className='d-block card bg-white mt-2 shadow my-2 curve-5' key={index}>
                                    <div className='hr-row my-2'>
                                        <div className='w-full hr-row'>
                                            <img src={`https://copywavex.com/uploads/${trader.image}`} alt={trader.name} className='d-block circle-40-full pos-rel centered bg-grey' />
                                            <div className='ml-2 w-full'>
                                                <div className='hr-row'>
                                                    <h3>{trader.name}</h3>
                                                    {(trader.verified === "1") && (<img src='assets/twitter.jpeg' className='ml-2' style={{width:"15px"}}  alt='te'/>)}
                                                </div>
                                                <div className='hr-row spacex-2'>
                                                    <span className="nmtext-green">{trader.badge}</span>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (trader.copier.length > 0) ? (
                                                trader.copier.map((copier, index) => (
                                                    (copier.accepted === "1") ? (
                                                        <div className='ml-2' key={index} >
                                                            <button className='btn px-4 bg-green text-white'>Accepted</button>
                                                        </div>
                                                    ) : (
                                                        <div className='ml-2' key={index} >
                                                            <button className='btn px-4 bg-blue text-white'>Requested</button>
                                                        </div>
                                                    )
                                                ))
                                            ) : (
                                                <div className='ml-2'>
                                                    <button className='btn px-4' data-trader={trader.id} onClick={TradeCopyRequest}>copy</button>
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='hr-row justify-between'>
                                        <div className='d-col'>
                                            <div className='my-2'>
                                                <p>ROI</p>
                                                <p className='text-green'>+{trader.roi}%</p>
                                            </div>
                                            <div className=' my-2'>
                                                <p>Win Rate</p>
                                                <p>+{trader.win_percent}%</p>
                                            </div>
                                        </div>
                                        <div className='d-col text-center'>
                                            <div className='my-2'>
                                                <p>Minimum</p>
                                                <p>+{trader.minimum}</p>
                                            </div>
                                            <div className='my-2'>
                                                <p>Stability Index</p>
                                                <p>{trader.stabilityIndex}</p>
                                            </div>
                                        </div>
                                        <div className='d-col text-right'>
                                            <div className='my-2'>
                                                <p>Total wins</p>
                                                <p>+{trader.total_wins}</p>
                                            </div>
                                            <div className='my-2'>
                                                <p>Followers</p>
                                                <p>{trader.followers}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <BottomNav copyTraders='active' />
        </>
    )
}

export default CopyTraders