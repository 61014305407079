import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { MailOutline, Person, ShieldOutline } from 'react-ionicons';
import { useAuth } from '../../authentication/userAuth';

const Verification = () => {
    const { user } = useAuth();
    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);
    return (
        <>
            <div className="content d-col scroll-y">
                <div className='card d-row-col' style={{ margin: "0 auto" }}>
                    <div className='w-full'>
                        <div className='d-col centered my-4'>
                            {
                                (user.profilephoto === 'human.png') ? (
                                    <div className='circle-80-full pos-rel centered bg-grey mx-2'>
                                        <h3 className='text-black text-bold'>{user?.fullname.split(' ')[0][0]}{user?.fullname.split(' ')[1][0]}</h3>
                                    </div>
                                ) : (
                                    <img src={`https://copywavex.com/uploads/${user?.profilephoto}`} className="img-cover circle-80-full pos-rel centered bg-grey mx-2" alt={user.fullname} />
                                )
                            }
                            <h1 className='text-center my-4 text-black'>{user.fullname}</h1>
                        </div>
                        <Link className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/verifications/kyc' >
                            <ShieldOutline />
                            <div className='w-full hr-row justify-between'>
                                <p>
                                    Kyc Verification
                                </p>
                                <div className='text-right'>
                                    {
                                        (user.kycVerifyStatus === "1") ? (
                                            <span className='text-green' style={{ "fontSize": "12px" }}>
                                                Verified
                                            </span>
                                        ) : (
                                            (user.kycVerifyStatus === "3") ? (
                                                <span className='text-blue' style={{"fontSize": "12px" }}>
                                                    Under review
                                                </span>
                                            ) : (
                                                <span className='text-red' style={{"fontSize": "12px" }}>
                                                    Not verified
                                                </span>
                                            )
                                        )
                                    }
                                </div>
                            </div>

                        </Link>
                        <Link className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/verifications/email' >
                            <MailOutline />
                            <div className='w-full hr-row justify-between'>
                                <p>Email Verification</p>
                                <div className='text-right'>
                                    {
                                        (user.emailVerificationStatus === "1") ? (
                                            <span className='text-green' style={{ "fontSize": "12px" }}>
                                                Verified
                                            </span>
                                        ) : (
                                            <span className='text-red' style={{ "fontSize": "12px" }}>
                                                Skipped
                                            </span>
                                        )
                                    }
                                </div>

                            </div>
                        </Link>
                        <Link className='my-4 py-2 px-2 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/verifications/id' >
                            <Person />
                            <div className='w-full hr-row justify-between'>
                                <p>ID Verification</p>
                                <div className='text-right'>
                                    {
                                        (user.idVerifyStatus === "1") ? (
                                            <span className='text-green' style={{ "fontSize": "12px" }}>
                                                Verified
                                            </span>
                                        ) : (
                                            (user.idVerifyStatus === "3") ? (
                                                <span className='text-blue' style={{"fontSize": "12px" }}>
                                                    Under review
                                                </span>
                                            ) : (
                                                <span className='text-red' style={{"fontSize": "12px" }}>
                                                    Not verified
                                                </span>
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Verification