import React, { useEffect, useState } from 'react';
import { AddOutline, NewspaperOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import month from '../../components/datepicker';
import { useAuth } from '../../authentication/userAuth';

const WithdrawalList = () => {
    const { user } = useAuth();
    const [withdraws, setWithdraws] = useState([]);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);
    
    useEffect(() => {
        async function fetchData() {
            const resp = await fetch('https://copywavex.com/api/user/withdraw/all/', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const response = await resp.json();
            setWithdraws(response.withdraws);
        }
        fetchData();
    }, [user.owner_id])
    return (
        <>
            <div className="content d-col">
                <div className='d-col flex-1'>
                    <div className="card h-full py-2 px-2">
                        <div className='alert alert__secondary my-2 mx-4'>Withdrawals will be Pending until there are sufficient confirmations.</div>
                        {
                            (withdraws?.length > 0) ? (
                                withdraws?.map((item, index) => (
                                    <div className="w-full hr-row justify-between px-2 py-2 my-2 bg-grey curve-10" key={index}>
                                        <Link to={`/user/withdrawals/single/${item.id}`} className='w-full hr-row spacex-2'>
                                            <div className="coll l1 s2">
                                                <center>
                                                    <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                                    <span className="app-trading-day">
                                                        {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                                    </span>
                                                </center>
                                            </div>
                                            <div className="w-full">
                                                Withdrawal OF ${item.amount} FROM {item.from_wallet.toUpperCase()} TO MY {item.currency} WALLET <br />
                                                {item.address}
                                            </div>
                                        </Link>
                                        <div className="text-bold" style={{ "textAlign": "right", "fontSize": "14px" }}>{item.status}</div>
                                    </div>
                                ))
                            ) : (
                                <div className="h-full centered">
                                    <div className="text-center">
                                        <div className="text-center">
                                            <NewspaperOutline className="text-xl" />
                                        </div>
                                        <p>Transaction will appear here</p>
                                        <p>Cannot find your transactions <Link to="/user/withdrawals/all/1" style={{ color: "blue" }}>Withdraw now</Link></p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="fixed-action-btnn">
                    <Link title="New withdraws" className="floating bg-blue centered circle-30-full" id="btnn" to="/user/withdrawals/all/1">
                        <AddOutline color={"#fff"}/>
                    </Link>
                </div >
            </div>
        </>
    )
}

export default WithdrawalList
