import { useNavigate, useOutlet } from "react-router-dom";
import { useAuth } from "./userAuth";
import { useContext, useEffect } from "react";
import Header from "../components/header";
import { AuthContext } from "../context";

export const ProtectedLayout = () => {
  const { user, refreshUserData } = useAuth();
  const { setNotifications, setTotalBalance } = useContext(AuthContext);
  const outlet = useOutlet();
  const navigate = useNavigate();

  useEffect(() => {
    if(!user) {
      navigate('/login');
    }
  }, [navigate, user]);

  useEffect(() => {
    const getUser = async () => {
      const resp = await fetch('https://copywavex.com/api/user/profile', {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id: user.owner_id })
      });
      const response = await resp.json();
      refreshUserData(response.user);
      setTotalBalance(response.balance);

      if (user.emailVerificationStatus === "0") {
        navigate("/verify/email", {
          replace:true
        });
      }
    }
    
    getUser();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://copywavex.com/api/user/notifications/all`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          userid: user.owner_id
        })
      })
      const response = await data.json();
      setNotifications(response.notifications.filter(elem => elem.seen === "0"));
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.owner_id]);

  return (
    <div className="r-app">
      <Header user={user} />
      {outlet}
    </div>
  );
};