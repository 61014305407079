import React, { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useAuth } from '../authentication/userAuth';
import { AuthContext } from '../context';

const NotificationSingle = () => {
    const { user } = useAuth();
    const { id } = useParams();
    const [notification, setNotification] = useState({});
    const { setNotifications } = useContext(AuthContext);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`https://copywavex.com/api/user/notifications/single`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id })
            })
            const response = await data.json();
            if (response.status === 200) {
                setNotification(response.notifications)
            }
        }

        setTimeout(async () => {
            await fetch(`https://copywavex.com/api/user/notification/viewed/`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id })
            })
        }, 1000);

        setTimeout(async () => {
            const data = await fetch(`https://copywavex.com/api/user/notifications/all`, {
                method: "POST",
                headers: {
                  "content-type": "application/json"
                },
                body: JSON.stringify({
                  userid: user.owner_id
                })
              })
              const response = await data.json();
              setNotifications(response.notifications.filter(elem => elem.seen === "0" ));
        }, 2000);

        fetchData();
    }, [id, setNotifications, user.owner_id]);
    return (
        <>
            <div className="content d-col mb-32 scroll-y">
                <div className='d-col flex-1'>
                    <div className="card h-full py-2">
                        <section className="container">
                            <div className='d-row-col'>
                                <div className="col l4 s12 ">
                                    <h3 className="app-trading-day">
                                        {notification.subject}
                                    </h3>
                                </div>
                                <div className="col l8 s12 d-col spacex-2">
                                    <div className='flex-1'>
                                        {notification.body}
                                    </div>
                                    <div className='my-2 fw-bold' style={{marginTop:"auto"}}>
                                        <div className="text-small">{notification.createdAt}</div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>

    )
}

export default NotificationSingle
