import React, { useContext, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../context';

const DepositStep1 = () => {
    const { theme } = useContext(ThemeContext);
    const navigate = useNavigate();
    const [amount, setAmount] = useState(0);
    const [account, setAccount] = useState('trading_deposit');

    const handleSubmission = (e) => {
        e.preventDefault();
        if (amount === 0) {
            toast.error("Please enter amount");
            return;
        }
        navigate(`/user/deposits/fund/step3/fund/${amount}/${account}`);
    }
    return (
        <>
            <div className="content d-col">
                <div className='flex-1 centered'>
                    <div className="h-full w-full d-col justify-center">
                        <div className="card bg-grey"><br />
                        <h2 className='text-center'>Fund Account</h2><br />
                            <form autoComplete="off" onSubmit={handleSubmission} className='px-2'>
                                <div className="">
                                    <div className='input-field'>
                                        <label htmlFor="amount">Amount</label>
                                        <div className='input-with-icon'>
                                            <span className="prefix">USD</span>
                                            <input
                                                placeholder='Enter amount'
                                                type="number"
                                                id="amount"
                                                min="100"
                                                step="any"
                                                inputMode="decimal"
                                                name="amount"
                                                className="form-control"
                                                required
                                                value={amount}
                                                onChange={(e) => setAmount(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                    <div className="input-field">
                                        <label>Account</label>
                                        <select id="account" className="form-control"
                                            value={account}
                                            onChange={(e) => setAccount(e.target.value)}>
                                            <option value="trading_deposit">Trading Deposit</option>
                                        </select>
                                    </div>
                                </div>
                                <br /><br />
                                <div className='input-field'>
                                    <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Proceed</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DepositStep1
