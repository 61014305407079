import React from 'react'
import { Star, StarOutline } from 'react-ionicons';

const Fft = ({ myWatch, id, Wishlist, removeWishlist }) => {
    const isFound = myWatch.includes(id.toString());

    return (
        isFound ? (
            <div className="coll l1  pointer" style={{ "paddingTop": "10px", textAlign: 'right' }}  onClick={removeWishlist}>
                <Star color={'#6648fe'}/>
            </div>
        ) : (
            <div className="coll l1 watchlist  pointer" style={{ "paddingTop": "10px", textAlign: 'right' }} onClick={Wishlist}>
                <StarOutline color={'#6648fe'}/>
            </div>
        )
    )
}

export default Fft
