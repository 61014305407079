import React, { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ArrowDownOutline, ArrowUpOutline, CardOutline, EllipsisHorizontal, NewspaperOutline } from 'react-ionicons';
import Coinprices from '../components/coinprices';
import month from '../components/datepicker';
import { useAuth } from '../authentication/userAuth';
import { AuthContext } from '../context';

const Wallet = () => {
  const { coin } = useParams();
  const { coindata } = useContext(AuthContext);
  const [bal, setBal] = useState(0);

  let g = coindata.filter(elem => elem.shortName.toLowerCase() === coin.toLowerCase())[0];
  let gg = g.icon;

  const { user } = useAuth();
  const [swaps, setSwaps] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const dd = await fetch('https://copywavex.com/api/user/wallets/balance', {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id: user.owner_id })
      });
      const resp = await dd.json();
      let d = 0;
      Object.entries(resp).map(([key, val], index) => {
        if (key === `${coin}_main_balance` || key === `${coin}_trading_balance`) {
          d += Number(val);
        }
        return key;
      });
      setBal(d);
    }
    fetchData();
  }, [coin, user.owner_id]);


  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://copywavex.com/api/user/swaps`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({
          userid: user.owner_id
        })
      })
      const response = await data.json();
      setSwaps(response.swaps.filter(elem => elem.baseCoin.toLowerCase() === coin.toLowerCase()));
    }
    fetchData();
  }, [coin, user.owner_id]);

  useEffect(() => {
    document.body.classList.add('remove-tidio');
}, []);

  return (
    <>
      <div className="content d-col bg-white scroll-y">
        <Coinprices coin={coin} height={200} />
        <div className="bb-1 h-200 my-2">
          <div className="centered my-4">
            <div className="text-center">
              <div className="w-full">
                <img className="circle-40-full" src={`${gg}`} alt={coin.toLowerCase()} />
              </div>
              <h3 className="mt-2">
                {bal} {coin}
              </h3>
              {/* <p className="text-small text-muted">$0.00</p> */}
            </div>
          </div>
          <div className="hr-row justify-around">
            <div className="d-col align-center my-2">
              <div className="centered circle-40-full bg-grey">
                <ArrowUpOutline />
              </div>
              <div className="text-mid">
                Send
              </div>
            </div>
            <div className="d-col align-center my-2">
              <div className="centered circle-40-full bg-grey">
                <ArrowDownOutline />
              </div>
              <div className="text-mid">
                Receive
              </div>
            </div>
            <div className="d-col align-center my-2">
              <div className="centered circle-40-full bg-grey">
                <CardOutline />
              </div>
              <div className="text-mid">
                Buy
              </div>
            </div>
            <div className="d-col align-center my-2">
              <div className="centered circle-40-full bg-grey">
                <EllipsisHorizontal />
              </div>
              <div className="text-mid">
                More
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1 py-2 px-2">
          {
            (swaps?.length > 0) ? (
              swaps?.map((item, index) => (
                <div className="w-full hr-row justify-between px-2 py-2 my-2 bg-grey curve-10" key={index}>
                  <div className='w-full hr-row spacex-2'>
                    <div className="coll l1 s2">
                      <center>
                        <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                        <span className="app-trading-day">
                          {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                        </span>
                      </center>
                    </div>
                    <div className="w-full">
                      Swapped ${item.amount}<br /> FROM {item.fromWallet.split('_').join(' ').toUpperCase()}  TO {item.toWallet.split('_').join(' ').toUpperCase()}
                    </div>
                  </div>
                  <div className="text-bold" style={{ "textAlign": "right", "fontSize": "14px" }}>{item.status}</div>
                </div>
              ))
            ) : (
              <div className="h-full centered">
                <div className="text-center">
                  <div className="text-center">
                    <NewspaperOutline className="text-xl" />
                  </div>
                  <p>Transaction will appear here</p>
                  <p>Cannot find your transactions <Link to="/user/deposits" style={{ color: "blue" }}>deposit now</Link></p>
                </div>
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}

export default Wallet