import React, { useEffect, useState } from 'react';
import { useAuth } from '../../authentication/userAuth';
import IndexPrices from '../../components/indexPrices';

const WatchList = () => {
    const { user } = useAuth();
    const [gen, setGen] = useState([]);
    const [up, setUp] = useState(0);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch('http://localhost/tradexio/api/user/trading/wishlist/all', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const repsonse = await resp.json();
            setGen(repsonse.wishlist);
        }
        fetchData();
    }, [up, user.owner_id])

    const relo = (v) => {
        setUp(up + 1);
    }
    return (
        <>

            <div className="content d-col pos-rel scroll-y">
                <div className='d-col flex-1'>
                    <div className='card'>
                        <ul className="collection d-col spacex-2">
                            {
                                (gen?.length > 0) ? (
                                    gen.map((data) => (
                                        <IndexPrices data={data} addToWish={false} fr={true} currentUser={user.id} relo={relo} />
                                    ))

                                ) : (
                                    <li className="card shadow bg-white my-2 curve-5 d-col justify-center" style={{ height: "80px" }}>
                                        <p className="text-center">
                                            <span className="app-font-normal">No Watch list yet</span>
                                        </p>
                                    </li>
                                )
                            }
                        </ul>
                        <br /><br />
                    </div>
                </div>
            </div>
        </>
    )
}

export default WatchList