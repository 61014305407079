import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../authentication/userAuth';
import { InfinitySpin } from 'react-loader-spinner';
import { ThemeContext } from '../../context';
import { CashOutline, WalletOutline } from 'react-ionicons';

const Withdrawal = () => {
  const { theme } = useContext(ThemeContext);
  const { user } = useAuth();
  const { crypto, name } = useParams();
  const navigate = useNavigate();

  const [isLoading, setIsloading] = useState(false);
  // const [balance, setBalance] = useState("trading_deposit");
  const [currency, setCurrency] = useState("USDT");
  const [wallet, setWallet] = useState('');
  const [amount, setAmount] = useState('');
  // const [account, setAccount] = useState("trading_deposit");
  // const [error, setError] = useState('');
  // const [wallets, setWallets] = useState([]);

  useEffect(() => {
    document.body.classList.add('remove-tidio');
}, []);

  useEffect(() => {
    if (crypto) {
      setCurrency(crypto);
    }
  }, [crypto, user.owner_id]);

  const handleWithdraw = async (e) => {
    e.preventDefault();
    setIsloading(true);

    let data = {
      to_wallet: wallet,
      withdrawer_id: user.owner_id,
      from_wallet: `System ${currency} wallet`,
      currency: currency,
      amount: amount,
      withdraw_from: "trading_deposit"
    }

    const dd = await fetch('https://copywavex.com/api/user/withdraw/add', {
      method: "POST",
      headers: {
        "content-type": "application/json"
      },
      body: JSON.stringify(data)
    });

    const resp = await dd.json();
    if (resp.status === 200) {
      setIsloading(false);
      toast.success(resp.message);
      setTimeout(() => {
        navigate('/user/withdrawals/list');
      }, 2000);
    } else {
      setIsloading(false);
      toast.error(resp.message)
    }
  }
  return (
    <>
      <div className="content d-col scroll-y">
        <div className='flex-1 centered'>
          <div className="h-full w-full d-col justify-center">
            <div className="card bg-grey" style={{ margin: "0 auto" }}><br />
              <h2 className='text-center'>Fund Withdrawal</h2><br />
              <Link to={`/user/withdrawals/list`} className='text-center text-green d-block'>
                All Withdrawals
              </Link><br />
              <form autoComplete="off" onSubmit={handleWithdraw} className='px-2 my-2'>
                {/* <input type='hidden' name='from_account' className="form-control" value={account} /> */}
                <div className="">
                  {/* <div className="input-field">
                    <label>From</label>
                    <select id="from_account" name='from_account' className="form-control"
                      value={account}
                      onChange={(e) => setAccount(e.target.value)} required>
                      <option value='trading_deposit' selected>Available balance</option>
                    </select>
                  </div> */}
                  <div className="input-field">
                    <label>Coin</label>
                    <select id="currency" name='currency' className="form-control"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)} required>
                      <option value={currency}>{name}</option>
                    </select>
                    {/* <select id="currency" name='currency' className="form-control"
                      value={currency}
                      onChange={(e) => setCurrency(e.target.value)} required>
                      {
                        (wallets) && (
                          Object.entries(wallets).map(([key], index) => (
                            <option key={index} value={key}>{key.split('_').join(' ')}</option>
                          ))
                        )
                      }
                    </select> */}
                  </div>

                  <div className="input-field">
                    <label>Address</label>
                    <div className="input-with-icon">
                      <input
                        placeholder='Input or press and hold to paste the withdrawal address'
                        type="text"
                        id="to_wallet"
                        name="amount"
                        className="form-control"
                        required
                        value={wallet}
                        onChange={(e) => setWallet(e.target.value)}
                      />
                      <WalletOutline />
                    </div>
                  </div>

                  <div className='input-field'>
                    <label htmlFor="amount">Amount</label>
                    <div className="input-with-icon">
                      <input
                        placeholder='Enter amount'
                        type="number"
                        id="amount"
                        min="100"
                        step="any"
                        inputMode="decimal"
                        name="amount"
                        className="form-control"
                        required
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                      <CashOutline />
                    </div>
                  </div>

                </div>
                <div className='my-2 hr-row justify-between'>
                  <h4>Available Balance</h4>
                  <div>{Intl.NumberFormat('en-US').format(user.trading_deposit)}</div>
                </div>
                <div className='bg-grey'>
                  <h5>Note:</h5>
                  <div className='hr-row justify-between'>
                    <div style={{fontSize:"small"}}>Daily Remaining Limit</div>
                    <div>1,000,000/1,000,000 USDT</div>
                  </div>
                </div>
                <br />

                <div className='input-field'>
                <div className='hr-row justify-between'>
                    <div>Withdrawal Fees</div>
                    <span>
                      1.5 {currency}
                    </span>
                  </div>
                  {
                    isLoading ? (
                      <div className='flex justify-center items-center'>
                        <InfinitySpin
                          visible={true}
                          width="200"
                          color="#205fb0"
                          ariaLabel="infinity-spin-loading"
                        />
                      </div>

                    ) : (
                      <div className='hr-row'>
                        <div className='col l6 s6'>
                          Amount Received <br /> {(amount > 0)&&(amount - 1.5)} {currency}
                        </div>
                        <div className='col l6 s6'>
                          <button type="submit" className={(theme === 'light') ? "btn btn-full bg-blue-light text-white" : "btn btn-full bg-blue text-white"}>Withdraw</button>
                        </div>
                      </div>
                    )
                  }
                  <ToastContainer />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Withdrawal
