import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from '../authentication/userAuth';
import { useNavigate } from 'react-router-dom';
import Header from '../components/header';
import { ImageOutline } from 'react-ionicons';
import { InfinitySpin } from 'react-loader-spinner';
import { toast, ToastContainer } from 'react-toastify';
import { ThemeContext } from '../context';

const VerifyID = () => {
    const { theme } = useContext(ThemeContext);
    const { user, refreshUserData } = useAuth();
    const [filename1, setFilename1] = useState('');
    const [idType, setIdType] = useState('Passport');
    const [filename2, setFilename2] = useState('');
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const getUser = async () => {
            const resp = await fetch('https://copywavex.com/api/user/profile', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const response = await resp.json();
            refreshUserData(response.user);
        }
        getUser();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getUser = async () => {
        const resp = await fetch('https://copywavex.com/api/user/profile', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ id: user.owner_id })
        });
        const response = await resp.json();
        refreshUserData(response.user);
    }

    const uploadFile1 = (e) => {
        setFilename1(e.target.files[0].name)
    }

    const uploadFile2 = (e) => {
        setFilename2(e.target.files[0].name)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);
        try {
            const formData = new FormData();
            formData.append("userId", user.owner_id);
            formData.append("idType", idType);
            formData.append(`front`, e.target.front.files[0]);
            formData.append(`back`, e.target.back.files[0]);
            const resp = await fetch("https://copywavex.com/api/user/verification/identity-upload", {
                method: 'POST',
                body: formData
            });
            const response = await resp.json();
            if (response.status === 200) {
                toast.success(response.message);
                setIsloading(false)
                setTimeout(() => {
                    navigate('/user');
                }, 2000);
            } else {
                setIsloading(false);
                toast.error(response.message);
            }

        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    const SkipIdVerification = async () => {
        await fetch('https://copywavex.com/api/user/verification/id/skip', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({
                userid: user.owner_id
            })
        });
        getUser();
        navigate('/user');
    }
    return (
        <div className="r-app">
            <Header />
            <div className="content d-col scroll-y">
                <div className="card bg-grey flex-1 d-col" style={{ margin: "0 auto" }}>
                    <h2 className="text-center">Verify Your Identity ( Upload ID)</h2><br />
                    <p className='text-center'>
                        Please verify your identity by uploading a valid government issued identification card.
                        You may experience difficulties when uploading from an ios device.
                        Make sure your browser has camera access in your ios settings.
                    </p>
                    <div className="cardd-panel center">
                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <select
                                    name="idType"
                                    className="form-control form-select"
                                    value={idType}
                                    onChange={(e) => {
                                        setIdType(e.target.value)
                                    }}
                                >
                                    <option selected value="Passport">
                                        Passport
                                    </option>
                                    <option value="Drivers License">
                                        Drivers License
                                    </option>
                                    <option value="National Id">
                                        National ID
                                    </option>
                                </select>
                            </div>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <label htmlFor='front' className='hr-row spacex-2' style={{ width: '100px', fontSize: "x-small", cursor: "pointer" }}>
                                        <ImageOutline /> select front
                                    </label>
                                    <input
                                        type="file"
                                        accept=".jpg,.png,.jpeg"
                                        id="front"
                                        name="front"
                                        className='form-control'
                                        onChange={uploadFile1}
                                        style={{ visibility: "hidden", display: "none" }}
                                        required
                                    />
                                    <input className='form-control' type="text" defaultValue={filename1} readOnly />
                                </div>
                            </div>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <label htmlFor='back' className='hr-row spacex-2' style={{ width: '100px', fontSize: "x-small", cursor: "pointer" }}>
                                        <ImageOutline /> select front
                                    </label>
                                    <input
                                        type="file"
                                        accept=".jpg,.png,.jpeg"
                                        id="back"
                                        name="back"
                                        className='form-control'
                                        onChange={uploadFile2}
                                        style={{ visibility: "hidden", display: "none" }}
                                        required
                                    />
                                    <input className='form-control' type="text" defaultValue={filename2} readOnly />
                                </div>
                            </div>
                            {
                                isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>

                                ) : (
                                    <div className='d-col spacex-2'>
                                        <div className='input-field'>
                                            <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Upload</button>
                                        </div>

                                        <div className='input-field'>
                                            <button type='button' onClick={SkipIdVerification} className='btn btn-full bg-blue text-white'>
                                                Skip Verification
                                            </button>
                                        </div>
                                    </div>
                                )
                            }
                        </form>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default VerifyID