import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import month from '../../components/datepicker'
import { AddOutline, NewspaperOutline } from 'react-ionicons';
import { useAuth } from '../../authentication/userAuth';

const DepositList = () => {
    const { user } = useAuth();
    const [deposits, setDeposits] = useState([]);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`https://copywavex.com/api/user/deposits/fund/all`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    depositor: user.owner_id
                })
            })
            const response = await data.json();
            setDeposits(response.deposits)
        }
        fetchData();
    }, [user.owner_id])
    return (
        <>
            <div className="content d-col mb-32 scroll-y">
                <div className='d-col flex-1'>
                    <div className="card h-full py-2" style={{margin:"0 auto"}}>
                        <div className='alert alert__secondary my-2 mx-4'>Deposits will be Pending until there are sufficient confirmations on the blockchain.</div>
                        {
                            (deposits?.length > 0) ? (
                                deposits?.map((item, index) => (
                                    <div className="w-full hr-row justify-between px-2 py-2 my-2 bg-grey curve-10" key={index}>
                                        <Link to={`/user/deposits/single/${item.id}`} className='w-full hr-row spacex-2'>
                                            <div className="coll l1 s2">
                                                <center>
                                                    <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                                    <span className="app-trading-day">
                                                        {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                                    </span>
                                                </center>
                                            </div>
                                            <div className="w-full">
                                                DEPOSITED FUNDS <br /> FOR {item.reason.toUpperCase()}
                                            </div>
                                        </Link>
                                        <div className="text-bold" style={{ "textAlign": "right", "fontSize": "14px" }}>{item.status}</div>
                                    </div>
                                ))
                            ) : (
                                <div className="h-full centered">
                                    <div className="text-center">
                                        <div className="text-center">
                                            <NewspaperOutline className="text-xl" />
                                        </div>
                                        <p>Transaction will appear here</p>
                                        <p>Cannot find your transactions <Link to="/user/deposits/" style={{ color: "blue" }}>deposit now</Link></p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <div className="fixed-action-btnn">
                    <Link title="New Deposit" className="floating bg-blue centered circle-30-full" id="btn" to="/user/deposits/">
                        <AddOutline color={"#fff"}/>
                    </Link>
                </div >
            </div>
        </>
    )
}

export default DepositList
