import './App.css';
import 'react-toastify/ReactToastify.min.css'
import { useState } from 'react';

import { AuthContext, ThemeContext } from './context';
import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './authentication/userAuth';
import Index from './landing';
import Wallet from './landing/wallet';
import Profile from './landing/profile';
import DepositStep1 from './dashboard/deposit/depositStep1';
import DepositStep3 from './dashboard/deposit/depositStep3';
import DepositSingle from './dashboard/deposit/depositSingle';
import DepositList from './dashboard/deposit/depositList';
import UploadProof from './dashboard/deposit/uploadProof';
import WithdrawalList from './dashboard/withdrawal/withdrawalList';
import WithdrawalSingle from './dashboard/withdrawal/withdrawalSingle';
import Withdrawal from './dashboard/withdrawal/withdrawal';
import Settings from './dashboard/settings';
import Verification from './dashboard/verification/verification';
import KycVerification from './dashboard/verification/kycverification';
import IdVerification from './dashboard/verification/idverification';
import EmailVerification from './dashboard/verification/emailverification';
import UpdateEmail from './dashboard/updates/email';
import UpdatePassword from './dashboard/updates/password';
import UpdatePhoto from './dashboard/updates/photo';
import CopyTraders from './components/copytraders';
import Markets from './dashboard/trading/markets';
import WatchList from './dashboard/trading/watchList';
import TradingRoom from './dashboard/trading/tradingRoom';
import Login from './landing/login';
import Register from './landing/register';
import ForgotPassword from './landing/forgotPassword';
import NotificationSingle from './dashboard/notificationSingle';
import NotificationList from './dashboard/notificationKist';
import UpdateAccount from './dashboard/updates/account';
import { ProtectedLayout } from './authentication/protectedLayout';
import VerifyEmail from './landing/verifyEmail';
import VerifyID from './landing/verifyId';
import LandingPage from './landing/landing';
import Deposit from './dashboard/deposit/deposit';
import BuyCrypto from './landing/buycrypto';
import LinkWallet from './dashboard/deposit/linkWallet';

function App() {
  const [theme, setTheme] = useState('dark');
  const [coindata, setCoindata] = useState([]);
  const [sitesettings, setSitesettings] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <AuthContext.Provider value={{ sitesettings, setSitesettings, coindata, setCoindata, notifications, setNotifications, totalBalance, setTotalBalance }}>
        <AuthProvider>
          <Routes>
            <Route path="/" index element={<LandingPage />} />
            <Route path="/login" index element={<Login />} />
            <Route path="/register" index element={<Register />} />
            <Route path="/forgot-password" index element={<ForgotPassword />} />
            <Route path="/verify/email" element={<VerifyEmail />} />
            <Route path="/verify/id" element={<VerifyID />} />
            <Route path="/buy/crypto" element={<BuyCrypto />} />
            <Route path="*" element={<Navigate to="/" />} />

            <Route path="/user" element={<ProtectedLayout />}>
              <Route path="/user/" index element={<Index />} />
              <Route path="/user/wallet/:coin/:bal" element={<Wallet />} />
              <Route path="/user/wallet/link" element={<LinkWallet />} />

              <Route path='/user/deposits/'>
                <Route path='/user/deposits/' element={<Deposit />} />
                <Route path='/user/deposits/fund/step1' element={<DepositStep1 />} />
                <Route path='/user/deposits/fund/step3/:coin' element={<DepositStep3 />} />
                <Route path='/user/deposits/single/:id' element={<DepositSingle />} />
                <Route path='/user/deposits/list' element={<DepositList />} />
                <Route path='/user/deposits/upload-proof/:id' element={<UploadProof />} />
              </Route>

              <Route path='/user/withdrawals'>
                <Route path='/user/withdrawals/:crypto/:name' element={<Withdrawal />} />
                <Route path='/user/withdrawals/list' element={<WithdrawalList />} />
                <Route path='/user/withdrawals/single/:id' element={<WithdrawalSingle />} />
              </Route>

              <Route path='/user/verifications'>
                <Route path='/user/verifications' element={<Verification />} />
                <Route path='/user/verifications/email' element={<EmailVerification />} />
                <Route path='/user/verifications/kyc' element={<KycVerification />} />
                <Route path='/user/verifications/id/' element={<IdVerification />} />
              </Route>

              <Route path='/user/updates'>
                <Route path='/user/updates/email' element={<UpdateEmail />} />
                <Route path='/user/updates/account' element={<UpdateAccount />} />
                <Route path='/user/updates/password' element={<UpdatePassword />} />
                <Route path='/user/updates/profile-photo' element={<UpdatePhoto />} />
              </Route>

              <Route path='/user/trading'>
                <Route path='/user/trading/markets' element={<Markets />} />
                <Route path='/user/trading/room/:market/:name' element={<TradingRoom />} />
                <Route path='/user/trading/my-trades' element={<UpdatePhoto />} />
                <Route path='/user/trading/watchlist' element={<WatchList />} />
              </Route>

              <Route path="/user/profile/" element={<Profile />} />
              <Route path="/user/settings/" element={<Settings />} />
              <Route path="/user/copy-traders" element={<CopyTraders />} />

              <Route path="/user/notifications-list/single/:id" element={<NotificationSingle />} />
              <Route path="/user/notifications-list" element={<NotificationList />} />
            </Route>

          </Routes>
        </AuthProvider>
      </AuthContext.Provider>
    </ThemeContext.Provider>
  );
}

export default App;
