import React, { useEffect, useState } from 'react'
import { useAuth } from '../../authentication/userAuth';
import data from '../../cryptoprices';
import IndexPrices from '../../components/indexPrices';
import BottomNav from '../../components/bottomNav';

const Markets = () => {
    const { user } = useAuth();
    const [forexPrices, setForexPrices] = useState([]);
    const [stockPrices, setStockPrices] = useState([]);
    const [cryptoPricess, setCryptoPrices] = useState([]);
    const [etfPrices, setEtfPrices] = useState([]);
    const [indexPrices, setIndexPrices] = useState([]);

    const [watch, setWatch] = useState([]);
    const [up, setUp] = useState(0);

    const [gen, setGen] = useState([]);
    const [mkt, setMkt] = useState('stock');

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        for (const [key, value] of Object.entries(data.data)) {
            if (key === 'forex') {
                setForexPrices(value.data);
            }
            if (key === 'stock') {
                setStockPrices(value.data);
                setGen(value.data);
                setMkt('stock')
            }
            if (key === 'crypto') {
                setCryptoPrices(value.data);
            }
            if (key === 'etf') {
                setEtfPrices(value.data);
            }
            if (key === 'index') {
                setIndexPrices(value.data);
            }
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const resp = await fetch('https://copywavex.com/api/user/trading/wishlist/all/id', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const repsonse = await resp.json();

            setWatch(repsonse.wishlist);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [up, user.owner_id])

    const relo = (param) => {
        setUp(up + 1);
        if (param === 'stock') {
            setGen(stockPrices);
            setMkt('stock');
        }
        if (param === 'crypto') {
            setGen(cryptoPricess)
            setMkt('crypto')
        }
        if (param === 'forex') {
            setGen(forexPrices)
            setMkt('forex')
        }
        if (param === 'etf') {
            setGen(etfPrices)
            setMkt('etf')
        }
        if (param === 'index') {
            setGen(indexPrices)
            setMkt('index')
        }
    }
    return (
        <>
            <div className="content d-col pos-rel scroll-y mb-64">
                <div className='d-col flex-1'>
                    <div className='card d-col my-2'>
                        <div className="row justify-between text-center" style={{ "fontSize": "14px" }}>
                            <div className="text-center pointer" onClick={() => relo('stock')}>
                                <img src="assets/images/svgs/IconStocks.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
                                <span style={{ "color": "rgb(133, 137, 155)" }}>Stocks</span>
                            </div>
                            <div className="text-center pointer" onClick={() => relo('crypto')}>
                                <img src="assets/images/svgs/IconCrypto.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
                                <span style={{ "color": "rgb(133, 137, 155)" }}>Crypto</span>
                            </div>
                            <div className="text-center pointer" onClick={() => relo('forex')}>
                                <img src="assets/images/svgs/IconCurrencies.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
                                <span style={{ "color": "rgb(133, 137, 155)" }}>Forex</span>
                            </div>
                            <div className="text-center pointer" onClick={() => relo('etf')}>
                                <img src="assets/images/svgs/IconIndices.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
                                <span style={{ "color": "rgb(133, 137, 155)" }}>ETFs</span>
                            </div>
                            <div className="text-center pointer" onClick={() => relo('index')}>
                                <img src="assets/images/svgs/IconIndices.svg" style={{ "width": "18px", "height": "20px" }} alt="" /><br />
                                <span style={{ "color": "rgb(133, 137, 155)" }}>Index</span>
                            </div>
                        </div><br />
                        <ul className="d-col spacex-2 h-full">
                            {
                                gen && gen?.map((data) => (
                                    <IndexPrices
                                        data={data}
                                        addToWish={true}
                                        currentUser={user.owner_id}
                                        mrkt={mkt}
                                        relo={relo}
                                        fr={false}
                                        myWatch={watch}
                                        setUp={setUp}
                                    />
                                ))
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <BottomNav withdraws='active' />
        </>
    )
}

export default Markets