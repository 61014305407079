import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';


const WithdrawalSingle = () => {
  const navigate = useNavigate();
  const [withdraw, setWithdraw] = useState({})
  const { id } = useParams();

  useEffect(() => {
    document.body.classList.add('remove-tidio');
}, []);

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetch(`https://copywavex.com/api/user/withdraws/fund/single`, {
        method: "POST",
        headers: {
          "content-type": "application/json"
        },
        body: JSON.stringify({ id })
      })
      const response = await data.json();
      if (response.status === 200) {
        setWithdraw(response.withdraw);
      } else {
        navigate('/user/withdrawals/list');
      }
    }
    fetchData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])
  return (
    <>
      <div className="content d-col">
        <div className='flex-1 centered'>
          <div className="h-full w-full d-col justify-center">
            <div className="card bg-grey"><br />
              <h2 className='text-center'>Transaction Details</h2><br />
              <div className="w-full">
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Date</span>
                  <span>{withdraw.createdAt}</span>
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Wallet to be funded</span>
                  <span>{withdraw.currency} wallet</span>
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Wallet address</span>
                  <span>{withdraw.to_wallet}</span>
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Status</span>
                  {
                    (withdraw.status === 'Approved')? (
                      <span className='text-green'>Successful</span>
                    ) : (
                      <span className='text-red'>Failed</span>
                    )
                  }
                </div>
                <div className='w-full my-2 hr-row justify-between py-4'>
                  <span className="text-black text-bold">Amount</span>
                  <span>${Intl.NumberFormat('en-US').format(withdraw.amount)}</span>
                </div>
                {/* <div className="fixed-action-btnn">
                  <Link title="New Withdrawal" className="floating bg-blue centered circle-30-full" id="btnn" to="/withdrawal/new">
                    <AddOutline color={"#fff"}/>
                  </Link>
                </div > */}

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default WithdrawalSingle
