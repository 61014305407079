import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { SwapHorizontalOutline, WalletOutline } from 'react-ionicons';
import { toast, ToastContainer } from 'react-toastify';
import { AuthContext, ThemeContext } from '../../context';
import { useAuth } from '../../authentication/userAuth';
import { InfinitySpin } from 'react-loader-spinner';
import BottomNav from '../../components/bottomNav';
// import Coinprices from '../../components/coinprices';

const Deposit = () => {
    const { theme } = useContext(ThemeContext);
    const { user, refreshUserData } = useAuth();
    const { coindata, setCoindata } = useContext(AuthContext);

    const [amount, setAmount] = useState(0);
    const [swap, setSwap] = useState(false);
    const [coinToSwap, setCoinToSwap] = useState('');

    const [isLoading, setIsloading] = useState(false);
    const [up, setUp] = useState(0);
    // const [totalBalance, setTotalBalance] = useState(2000);
    // const [open, setOpen] = useState(settings.hideBalance);
    // const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const getUser = async () => {
            const resp = await fetch('https://copywavex.com/api/user/profile', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const response = await resp.json();
            refreshUserData(response.user);
        }
        getUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.owner_id, up]);

    useEffect(() => {
        const getCoindata = async () => {
            const resp = await fetch('https://copywavex.com/api/user/coindata', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const response = await resp.json();
            setCoindata(response.coindata)
        }
        getCoindata();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [up, user.owner_id]);

    const handldeSwap = async (e) => {
        e.preventDefault();
        setIsloading(true);

        try {
            let data = {
                fromWallet: e.target.wallet_from.value,
                amount: amount,
                userid: user.owner_id,
                toWallet: e.target.wallet_to.value,
                email: user.email,
                fee: 0.03,
                baseCoin: e.target.basecoin.value
            }

            const resp = await fetch('https://copywavex.com/api/user/balance/swap', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });

            const response = await resp.json();
            if (response.status === 200) {
                toast.success(response.message);
                setIsloading(false);
                setSwap(false);
                setUp(up + 1);
            } else {
                setIsloading(false)
                toast.error(response.message)
            }
        }
        catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    return (
        <>
            {
                swap && (
                    <form className='w-full h-full pos-fixed px-2 centered' style={{ top: 0 }} onSubmit={handldeSwap}>
                        <input id='basecoin' type="hidden" name='basecoin' value={coinToSwap} />
                        <div className='w-full h-full pos-fixed' style={{ top: 0, backgroundColor: "rgba(0,0,0,.75)" }} onClick={(e) => setSwap(!swap)}></div>
                        <div className='card d-col bg-d-grey px-2 py-2 curve-10 shadow' style={{ zIndex: "2" }}>
                            <div className='d-row-col spacex-2'>
                                <div className='input-field'>
                                    <label className='text-white'>From Wallet</label>
                                    <div className='input-with-icon'>
                                        <span className="prefix">
                                            <WalletOutline />
                                        </span>
                                        <select id="wallet_from" name='wallet_from' className="form-control">
                                            <option value={`${coinToSwap.toLowerCase()}_main_balance`} selected>{`${coinToSwap} Main balance`}</option>
                                            <option value={`${coinToSwap.toLowerCase()}_trading_balance`} >{`${coinToSwap} Trading balance`}</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='input-field'>
                                    <label htmlFor="amount" className='text-white'>Amount</label>
                                    <div className='input-with-icon'>
                                        <span className="prefix">USD</span>
                                        <input
                                            placeholder='Enter amount'
                                            type="number"
                                            id="amount"
                                            min="100"
                                            step="any"
                                            inputMode="decimal"
                                            name="amount"
                                            className="form-control"
                                            required
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='input-field'>
                                    <label className='text-white'>To Wallet</label>
                                    <div className='input-with-icon'>
                                        <span className="prefix">
                                            <WalletOutline />
                                        </span>
                                        <select id="wallet_to" name='wallet_to' className="form-control">
                                            <option value={`${coinToSwap.toLowerCase()}_trading_balance`} selected>{`${coinToSwap} Trading balance`}</option>
                                            <option value={`${coinToSwap.toLowerCase()}_main_balance`}>{`${coinToSwap} Main balance`}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            {
                                isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>

                                ) : (
                                    <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"} >
                                        <div className='w-full hr-row spacex-2 justify-center'>
                                            <span className='text-white'>Swap</span> <SwapHorizontalOutline color={"#fff"} />
                                        </div>
                                    </button>
                                )
                            }
                        </div>
                    </form>
                )
            }

            <div className="d-col content mb-2">
                {/* <Coinprices coin={'BTC'} height={200} /> */}
                <div className='my-4'>
                    <div className='hr-row px-2 justify-right'>
                        <Link className={(theme === 'light') ? "btn bg-blue-light text-white" : "btn bg-blue text-white"} to='/user/wallet/link'>
                            Link Wallet
                        </Link>
                    </div>
                </div>

                <div className="table-top w-full hr-row bg-yellow"> 
                    <div className="cc_"></div>
                    <div className="hr-row w-full">
                        <div className="cc">Main <br />balance</div>
                        <div className="cc">Transfers</div>
                        <div className="cc">Trading <br />balance</div>
                        <div className="cc">In Orders</div>
                    </div>
                </div>

                <div className='scroll-x scroll-y flex-1'>
                    <div className="table-div scroll-y d-col flex-1">
                        <div className="table-body h-full mb-16">
                            {
                                coindata.map((data, index) => (
                                    <div key={index} className="table-row w-full hr-row py-2" >
                                        <div className="trow-dataf" >
                                            <div className="hr-row">
                                                <img src={data.icon} alt={data.shortName} /> 
                                                {data.shortName}
                                            </div>
                                            <div className="hr-row">
                                                <Link className="nn text-green" to={`/user/deposits/fund/step3/${data.shortName}`}>Deposit</Link>
                                                <Link className="ml-1 nn text-red" to={`/user/withdrawals/${data.shortName}/${data.name}`}>Withdraw</Link>
                                            </div>
                                        </div>
                                        <div className="w-full hr-row">
                                            <div className="trow-data">{data[`${data.shortName.toLowerCase()}_main_balance`]}</div>
                                            <div className="trow-data">
                                                <div>
                                                    <SwapHorizontalOutline onClick={(e) => {
                                                        setCoinToSwap(data.shortName)
                                                        setSwap(!swap)
                                                    }} />
                                                </div>
                                            </div>
                                            <div className="trow-data">{data[`${data.shortName.toLowerCase()}_trading_balance`]}</div>
                                            <div className="trow-data">{data.referal_balance}</div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        <div style={{ height: "50px" }}></div>
                    </div>
                </div>
                <ToastContainer />
            </div>
            <BottomNav deposits='active' />
        </>
    )
}
export default Deposit;