import React, { useContext, useEffect, useState } from 'react'
import { KeyOutline, MailOutline } from 'react-ionicons';
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Header from '../components/header';
import { ThemeContext } from '../context';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const { theme } = useContext(ThemeContext);
    const [email, setEmail] = useState('');

    const [newPassword, setnewPassword] = useState('');
    const [ConfirmnewPassword, setConfirmnewPassword] = useState('');
    const [token, setToken] = useState('');

    const [isLoading, setIsloading] = useState(false);
    const [pinSent, setPinSent] = useState(false);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const handleSubmitSendPin = async (e) => {
        e.preventDefault();
        setIsloading(true);
        try {
            let data = {
                email
            }

            const resp = await fetch('https://copywavex.com/api/user/reset/send-token', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(data)
            });
            const response = await resp.json();

            if (response.status === 200) {
                toast.success(response.message);
                setIsloading(false);
                setPinSent(true);
            } else {
                toast.error(response.message);
                setIsloading(false);
            }

        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        if (ConfirmnewPassword === newPassword) {
            try {
                let data = {
                    newPassword,
                    email,
                    token
                }
                const resp = await fetch('https://copywavex.com/api/user/reset/password', {
                    method: "POST",
                    headers: {
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(data)
                });
                const response = await resp.json();

                if (response.status === 200) {
                    toast.success(response.message);
                    setIsloading(false);
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                } else {
                    toast.err(response.message);
                    setIsloading(false);
                }

            } catch (err) {
                setIsloading(false);
                toast.err(err.message);
            }
        } else {
            setIsloading(false)
            toast.err("Passsword does not match");
        }
    }
    return (
        <div className="r-app">
            <Header />
            <div className="content d-col">
                <div className="bg-grey px-2 curve-5">
                    <div className="d-col justify-content-center">
                        {
                            pinSent ? (
                                <div className="col s12 m12 l4 offset-l4"><br /><br />
                                    <div className="card">
                                        <h2 className='text-center'>Account Recovery</h2><br />
                                        <p className='text-center'>Please enter your new password and the token sent to your email <b>{email}</b></p><br />
                                        <center><br />
                                            <div>
                                                <form autocomplete="off" onSubmit={handleSubmit}>
                                                    <div className="">
                                                        <div className="input-field input-with-icon">
                                                            <input
                                                                type="password"
                                                                id="new_password"
                                                                name="new_password"
                                                                className='form-control'
                                                                placeholder='New password'
                                                                autocomplete="new-password"
                                                                required=""
                                                                value={newPassword}
                                                                aria-autocomplete="list"
                                                                onChange={(e) => setnewPassword(e.target.value)}
                                                            />
                                                            <label className="active" for="new_password">
                                                                <KeyOutline />
                                                            </label>
                                                        </div>
                                                        <div className="input-field input-with-icon">
                                                            <input
                                                                type="password"
                                                                id="confirm_new_password"
                                                                name="confirm_new_password"
                                                                autocomplete="new-password"
                                                                placeholder='Confirm password'
                                                                className='form-control'
                                                                required=""
                                                                value={ConfirmnewPassword}
                                                                onChange={(e) => setConfirmnewPassword(e.target.value)}
                                                            />
                                                            <label className="active" for="confirm_new_password">
                                                                <KeyOutline />
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <div className="input-field input-with-icon">
                                                                <input
                                                                    type="text"
                                                                    id="password_token"
                                                                    name="password_token"
                                                                    className='form-control'
                                                                    placeholder='Input token'
                                                                    maxlength="300"
                                                                    inputmode="text"
                                                                    required=""
                                                                    value={token}
                                                                    onChange={(e) => setToken(e.target.value)}
                                                                />
                                                                <label className="active" for="password_token">token</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        isLoading ? (
                                                            <InfinitySpin
                                                                visible={true}
                                                                width="200"
                                                                color="#205fb0"
                                                                ariaLabel="infinity-spin-loading"
                                                            />
                                                        ) : (
                                                            <>
                                                                <div className="input-field">
                                                                    <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Update</button>
                                                                </div><br /><br />

                                                            </>
                                                        )
                                                    }
                                                </form>
                                            </div><br />
                                        </center>
                                    </div>
                                </div>
                            ) : (
                                <div className="col s12 m12 l12">
                                    <div className="card">
                                        <h2 className='text-center'>Forgot password to your account</h2><br />
                                        <p className='text-center'>To recover your account please provide your email or username to find your account.</p>
                                        <center>
                                            <div>
                                                <form onSubmit={handleSubmitSendPin}>
                                                    <div className="row gy-2 gap-2">
                                                        <div className="col l12 s12">
                                                            <div className="form-field input-with-icon">
                                                                <label>
                                                                    <MailOutline />
                                                                </label>
                                                                <input
                                                                    type="text"
                                                                    className="form-control h-45"
                                                                    name="email"
                                                                    value={email}
                                                                    onChange={(e) => setEmail(e.target.value)}
                                                                    required
                                                                    autoFocus="off"
                                                                />
                                                            </div>
                                                        </div>
                                                        <br />
                                                    </div>
                                                    {
                                                        isLoading ? (
                                                            <div className='flex justify-center items-center'>
                                                                <InfinitySpin
                                                                    visible={true}
                                                                    width="200"
                                                                    color="#205fb0"
                                                                    ariaLabel="infinity-spin-loading"
                                                                />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className="input-field">
                                                                <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Send</button>
                                                                </div><br /><br />

                                                            </>
                                                        )
                                                    }
                                                </form>
                                            </div>
                                        </center>
                                    </div>
                                </div>
                            )
                        }
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ForgotPassword