import React, { useEffect, useState } from 'react';
import { useAuth } from '../authentication/userAuth';
import { NewspaperOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import month from '../components/datepicker';

const NotificationList = () => {
    const { user } = useAuth();
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetch(`https://copywavex.com/api/user/notifications/all`, {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    userid: user.owner_id
                })
            })
            const response = await data.json();
            setNotifications(response.notifications);
        }
        fetchData();
    }, [user.owner_id]);
    return (
        <>
            <div className="content d-col mb-32 scroll-y">
                <div className='d-col flex-1'>
                    <div className="card h-full py-2">
                        {
                            (notifications?.length > 0) ? (
                                notifications?.map((item, index) => (
                                    <div className="w-full hr-row justify-between px-2 py-2 my-2 bg-grey curve-10" key={index}>
                                        <Link to={`/user/notifications-list/single/${item.id}`} className='w-full hr-row spacex-2'>
                                            <div className="col l1 s2" style={{ "padding": "0px" }}>
                                                <center>
                                                    <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                                    <span className="app-trading-day">
                                                        {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                                    </span>
                                                </center>
                                            </div>
                                            <div className="w-full" style={{ "fontSize": "14px" }} >
                                                {item.subject}
                                            </div>
                                        </Link>
                                        
                                        <div className="col l2 s3" style={{ "textAlign": "right", "fontSize": "14px" }}>{
                                            (item.seen === '1') ? 'Read' : 'Unread'
                                        }</div>
                                    </div>
                                ))
                            ) : (
                                <div className="h-full centered">
                                    <div className="text-center">
                                        <div className="text-center">
                                            <NewspaperOutline className="text-xl" />
                                        </div>
                                        <p>Notifications will appear here</p>
                                        <p>Cannot find your transactions </p>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
        </ >
    )
}

export default NotificationList
