import React, { useContext, useEffect, useRef } from 'react'
import { HomeOutline, LogInOutline, MoonSharp, NotificationsCircle, PeopleOutline, PersonAddOutline, PersonOutline, SunnySharp, TimeOutline, WalletOutline } from 'react-ionicons';
import { Link } from 'react-router-dom';
import { AuthContext, ThemeContext } from '../context';
import { useAuth } from '../authentication/userAuth';

const Header = ({ user }) => {
    const closeBar = useRef(null);
    const navToggler = useRef(null);
    const sidebar = useRef(null);
    const { notifications } = useContext(AuthContext);
    const { theme, setTheme } = useContext(ThemeContext);
    const { mysettings } = useAuth();

    useEffect(() => {
        navToggler.current.onclick = () => {
            sidebar.current.style.right = 0;
            closeBar.current.style.display = "block";
        }

        sidebar.current.onclick = () => {
            sidebar.current.style.right = "-300px";
            closeBar.current.style.display = "none";
        }

        closeBar.current.onclick = () => {
            sidebar.current.style.right = "-300px";
            closeBar.current.style.display = "none";
        }
    }, []);

    const setSiteTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
        mysettings({
            theme: theme
        });
    }

    return (
        <div className={(theme === 'light') ? "header hr-row justify-between w-full px-2 py-2 bg-gren-light" : "header hr-row justify-between w-full px-2 py-2 bg-gren"}>
            <div className="logo">
                {
                    (user) ? (
                        <div className='hr-row spacex-2'>
                            {
                                (user?.profilephoto !== '') && (
                                    <Link to={'/user/profile'}>
                                        <div className='circle-30-full pos-rel centered bg-grey'>
                                            <img
                                                src={`https://copywavex.com/uploads/${user?.profilephoto}`}
                                                className="circle-30-full pos-rel centered bg-grey img-cover"
                                                alt={user?.fullname}
                                            />
                                        </div>
                                    </Link>
                                )
                            }
                            <h3 className='text-white'>Hi, {user?.fullname.split(' ')[0].toUpperCase()}</h3 >
                        </div>
                    ) : (
                        <Link to={'/'}>
                            <h1>Copywavex</h1>
                        </Link>
                    )
                }

            </div>
            <div className="">
                <div className="vv" id="closeBar" ref={closeBar}></div>
                <ul className="sidebar scroll-y" id="sidebar" ref={sidebar}>
                    {
                        (user) ? (
                            <>
                                <li className='g'>
                                    <div className='hr-row'>
                                        {
                                            (user?.profilephoto === 'human.png') ? (
                                                <Link to={'/user/profile'}>
                                                    <div className='circle-40-full pos-rel centered bg-grey mx-2 mt-4'>
                                                        <h3 className='text-black text-bold'>{user?.fullname.split(' ')[0][0]}{user?.fullname.split(' ')[1][0]}</h3>
                                                    </div>
                                                </Link>
                                            ) : (
                                                <Link to={'/user/profile'}>
                                                    <img
                                                        src={`https://copywavex.com/uploads/${user?.profilephoto}`}
                                                        className="circle-40-full pos-rel centered bg-grey mx-2 mt-4 img-cover"
                                                        alt={user?.fullname}
                                                    />
                                                </Link>
                                            )
                                        }
                                    </div>
                                </li>
                                <li>
                                    <Link to={'/user'}><HomeOutline /> Home</Link>
                                </li>
                                <li>
                                    <Link to={'/user/deposits'}><WalletOutline /> Wallet</Link>
                                </li>
                                <li>
                                    <Link to={'/user/deposits/list'}><TimeOutline /> Deposit history</Link>
                                </li>
                                <li>
                                    <Link to={'/user/withdrawals/list'}><TimeOutline /> Withdrawals History</Link>
                                </li>
                                <li>
                                    <Link to={'/user/trading/markets'}><PeopleOutline /> Trading</Link>
                                </li>
                                <li>
                                    <Link to={'/user/copy-traders'}><PeopleOutline /> Copy Traders</Link>
                                </li>
                                <li>
                                    <Link to={'/user/profile'}><PersonOutline /> Profile</Link>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className='g'>
                                    <div className='hr-row spacex-2 py-4 px-2'>
                                        <img src={`https://copywavex.com/uploads/logo.png`} className="pos-rel centered bg-grey mx-2" alt={`logo`} />
                                        <h3 className='text-black text-bold'>TRADEXIO</h3>
                                    </div>
                                </li>
                                <li>
                                    <Link to={'/login'}><LogInOutline /> Login</Link>
                                </li>
                                <li>
                                    <Link to={'/register'}><PersonAddOutline /> Register</Link>
                                </li>
                            </>
                        )
                    }
                </ul>
            </div>
            {
                (user) && (
                    <Link to={'/user/notifications-list'} className='pos-rel'>
                        <NotificationsCircle color={"#fff"} height="100px" width="50px" />
                        {
                            (user) && (
                                (notifications?.length > 0) && (
                                    <span className='pos-abs' style={{ width: "15px", height: "15px", borderRadius: "50%", backgroundColor: "red", top: "25px", left: "30px" }}></span>
                                )
                            )
                        }
                    </Link>
                )
            }
            <div className="pointer" onClick={setSiteTheme} style={{ width: "40px" }}>
                {
                    (theme === 'light') ? (
                        <MoonSharp color={"#fff"} />
                    ) : (
                        <SunnySharp color={"#fff"} />
                    )
                }
            </div>
            <div className="navToggler" id="navToggler" ref={navToggler}>
                &#9776;
            </div>
        </div>
    )
}

export default Header