import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { useAuth } from '../../authentication/userAuth';
import { InfinitySpin } from 'react-loader-spinner';
import month from '../../components/datepicker';
import { HomeOutline, HourglassOutline, HourglassSharp, StorefrontOutline } from 'react-ionicons';

const TradingRoom = () => {
    const { name } = useParams();
    const { user } = useAuth();

    const [up, setUp] = useState(0);
    const [amount, setAmount] = useState(0);
    const [leverage, setLeverage] = useState(1);
    const [time, setTime] = useState(10);
    const [isLoading, setIsloading] = useState(false);
    const [picked, setPicked] = useState('');
    const [openTrades, setOpenTrades] = useState([]);
    const [closedTrades, setclosedTrades] = useState([]);
    const [gen, setGen] = useState([]);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const getUser = async () => {
            const resp = await fetch('https://copywavex.com/api/user/trading/trades/all', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const response = await resp.json();
            setOpenTrades(response.open);
            setclosedTrades(response.closed);
            setGen(response.open);
        }
        getUser()
    }, [up, user.owner_id]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (amount === 0) {
            toast.error("please input amount for trade");
            return;
        }
        
        setIsloading(true);

        let data = {
            amount,
            userid: user.owner_id,
            leverage,
            coin: name,
            type: e.nativeEvent.submitter.dataset.type,
            time
        }

        const dd = await fetch('https://copywavex.com/api/user/trading/place-trade', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(data)
        })

        const resp = await dd.json();
        if (resp.status === 200) {
            setIsloading(false);
            toast.success('Trade placed successfully');
            setUp(up + 1);
        } else {
            setIsloading(false);
            toast.error(resp.message);
        }
    }
    return (
        <>
            <div className="content d-col scroll-y mb-8">
                <div className='d-col flex-1'>
                    <div className="h-full py-4">
                        <div>
                            <div className="row spacex-2">
                                <div className="col l1 s12 centered hide-on-med-and-down">
                                    <ul>
                                        <li className="tab text-center">
                                            <Link to="/user"><br /><br />
                                                <HomeOutline /> <br />HOME<br /><br />
                                            </Link>
                                        </li><br />
                                        <li className="tab text-center">
                                            <Link className={(picked === '') ? "active" : ""} to="#" onClick={() => {
                                                setPicked('');
                                                setGen(openTrades);
                                            }}><br />
                                                <StorefrontOutline /> <br />TRADE <br />ROOM<br />
                                            </Link>
                                        </li><br />
                                        <li className="tab text-center">
                                            <Link className={(picked === 'open') ? "active" : ""} to="#" onClick={() => {
                                                setGen(openTrades);
                                                setPicked('open');
                                            }}><br />
                                                <HourglassOutline /> <br />OPEN<br /> TRADES<br />
                                            </Link>
                                        </li><br />
                                        <li className="tab text-center">
                                            <Link className={(picked === 'closed') ? "active" : ""} to="#" onClick={() => {
                                                setGen(closedTrades);
                                                setPicked('closed');
                                            }}><br />
                                                <HourglassSharp /> <br />CLOSED TRADES<br />
                                            </Link>
                                        </li><br />
                                    </ul>
                                </div>

                                <div className="col l11 s12 px-2">
                                    <div className="d-row-col spacex-2">
                                        <div className={(picked === '') ? "col l3 s12 hide-on-med-and-down" : "col l6 s12 hide-on-med-and-down"}>
                                            <ul className="collection">
                                                {
                                                    (gen.length > 0) ? (
                                                        gen.map((item, index) => (
                                                            <li className="shadow bg-white my-2 curve-5 d-col justify-center" key={index}>
                                                                <div className="hr-row">
                                                                    <Link to={`#`} className='row spacex-2 py-2'>
                                                                        <div className="col l2 s2" style={{ "padding": "0px" }}>
                                                                            <center>
                                                                                <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                                                                <span className="app-trading-day">
                                                                                    {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                                                                </span>
                                                                            </center>
                                                                        </div>
                                                                        <div className="mx-2" style={{ "fontSize": "14px" }} >
                                                                            {item.coin}
                                                                            <br />
                                                                            Amount: {item.amount}
                                                                            <br />
                                                                            Leverage: {item.leverage}
                                                                            <br />
                                                                        </div>
                                                                    </Link>
                                                                    {
                                                                        (item.type === 'buy') ? (<div className="col l3 s3" style={{ color: "green" }}>Buy</div>) : (<div className="col l3 s3" style={{ color: "red" }}>Sell</div>)
                                                                    }
                                                                </div>
                                                            </li >
                                                        ))
                                                    ) : (
                                                        (picked === 'open' || picked === '') ? (
                                                            <li className="shadow bg-white my-2 curve-5 d-col justify-center" style={{ height: "80px" }}>
                                                                <p className="text-center">
                                                                    <span className="app-font-normal">NO OPEN TRADES</span>
                                                                </p>
                                                            </li>
                                                        ) : (
                                                            <li className="shadow bg-white my-2 curve-5 d-col justify-center" style={{ height: "80px" }}>
                                                                <p className="text-center">
                                                                    <span className="app-font-normal">NO CLOSED TRADES</span>
                                                                </p>
                                                            </li>
                                                        )
                                                    )
                                                }
                                            </ul>
                                        </div>

                                        <div className=" col l6 s12 m9 hide-on-med-and-down">
                                            <div style={{ "height": "530px" }}>
                                                <div className="tradingview-widget-container" style={{ "height": "99%" }}>
                                                    <div id="tradingview_ec926" style={{ "height": "100%" }}>
                                                        <div
                                                            id="tradingview_2697d-wrapper"
                                                            style={{ "position": "relative", "boxSizing": "contentBox", "fontFamily": "-apple - system, BlinkMacSystemFont, & quot; Trebuchet MS& quot;, Roboto, Ubuntu, sans-serif", "margin": "0px auto !important", "padding": "0px !important", "width": "100%", "height": "100%" }}
                                                        >
                                                            <iframe
                                                                title="advanced chart TradingView widget"
                                                                lang="en"
                                                                id="tradingview_2697d"
                                                                frameBorder="0"
                                                                allowtransparency="true"
                                                                scrolling="no"
                                                                allowFullScreen={true}
                                                                src={`https://s.tradingview.com/widgetembed/?hideideas=1&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en#%7B%22symbol%22%3A%22${name}%22%2C%22frameElementId%22%3A%22tradingview_2697d%22%2C%22interval%22%3A%221%22%2C%22range%22%3A%225D%22%2C%22save_image%22%3A%220%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22light%22%2C%22style%22%3A%223%22%2C%22timezone%22%3A%22America%2FNew_York%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22cryptologia.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22${name}%22%2C%22page-uri%22%3A%22cryptologia.net%2Fuser%2Ftraderoom%22%7D`}
                                                                style={{ "width": "100%", "height": "100%", "margin": "0px !important", "padding": "0px !important" }}>
                                                            </iframe>
                                                        </div>
                                                    </div>
                                                    <div className="tradingview-widget-copyright" style={{ "width": "100% " }}></div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            (picked !== '') ? (
                                                <div className={(picked === '') ? "col l4 s12 hide-on-large-only" : "col l6 s12 hide-on-large-only"}>
                                                    <ul className="colection">
                                                        {
                                                            (gen.length > 0) ? (
                                                                gen.map((item, index) => (
                                                                    <li className="shadow bg-white my-2 curve-5 d-col justify-center" style={{ height: "80px" }} key={index}>
                                                                        <div className="hr-row">
                                                                            <Link to={`#`} className='row spacex-2 py-2'>
                                                                                <div className="col l1 s2" style={{ "padding": "0px" }}>
                                                                                    <center>
                                                                                        <p className="app-trading-month">{month[item.createdAt.split(' ')[0].split('-')[1]]}</p>
                                                                                        <span className="app-trading-day">
                                                                                            {item.createdAt.split(' ')[0].split('-')[2].split('')[0]}{item.createdAt.split(' ')[0].split('-')[2].split('')[1]}
                                                                                        </span>
                                                                                    </center>
                                                                                </div>
                                                                                <div className="mx-2" style={{ "fontSize": "14px" }} >
                                                                                    {item.coin}
                                                                                    <br />
                                                                                    Amount: {item.amount}
                                                                                    <br />
                                                                                    Leverage: {item.leverage}
                                                                                    <br />
                                                                                </div>
                                                                            </Link>
                                                                            {
                                                                                (item.type === 'buy') ? (
                                                                                    <div className="col l2 s3" style={{ color: "green" }}>Buy</div>
                                                                                ) : (
                                                                                    <div className="col l2 s3" style={{ color: "red" }}>Sell</div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                    </li >
                                                                ))
                                                            ) : (
                                                                (picked === 'open' || picked === '') ? (
                                                                    <li className="shadow bg-white my-2 curve-5 d-col justify-center" style={{ height: "80px" }}>
                                                                        <p className="text-center">
                                                                            <span className="app-font-normal">NO OPEN TRADES</span>
                                                                        </p>
                                                                    </li>
                                                                ) : (
                                                                    <li className="shadow bg-white my-2 curve-5 d-col justify-center" style={{ height: "80px" }}>
                                                                        <p className="text-center">
                                                                            <span className="app-font-normal">NO CLOSED TRADES</span>
                                                                        </p>
                                                                    </li>
                                                                )
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            ) : (
                                                <div className="col l6 s12 m9 hide-on-large-only">
                                                    <div style={{ "height": "430px" }}>
                                                        <div className="tradingview-widget-container" style={{ "height": "99%" }}>
                                                            <div id="tradingview_ec926" style={{ "height": "100%" }}>
                                                                <div
                                                                    id="tradingview_2697d-wrapper"
                                                                    style={{ "position": "relative", "boxSizing": "contentBox", "fontFamily": "-apple - system, BlinkMacSystemFont, & quot; Trebuchet MS& quot;, Roboto, Ubuntu, sans-serif", "margin": "0px auto !important", "padding": "0px !important", "width": "100%", "height": "100%" }}
                                                                >
                                                                    <iframe
                                                                        title="advanced chart TradingView widget"
                                                                        lang="en"
                                                                        id="tradingview_2697d"
                                                                        frameBorder="0"
                                                                        allowtransparency="true"
                                                                        scrolling="no"
                                                                        allowFullScreen={true}
                                                                        src={`https://s.tradingview.com/widgetembed/?hideideas=1&amp;overrides=%7B%7D&amp;enabled_features=%5B%5D&amp;disabled_features=%5B%5D&amp;locale=en#%7B%22symbol%22%3A%22${name}%22%2C%22frameElementId%22%3A%22tradingview_2697d%22%2C%22interval%22%3A%221%22%2C%22range%22%3A%225D%22%2C%22save_image%22%3A%220%22%2C%22studies%22%3A%22%5B%5D%22%2C%22theme%22%3A%22light%22%2C%22style%22%3A%223%22%2C%22timezone%22%3A%22America%2FNew_York%22%2C%22studies_overrides%22%3A%22%7B%7D%22%2C%22utm_source%22%3A%22cryptologia.net%22%2C%22utm_medium%22%3A%22widget_new%22%2C%22utm_campaign%22%3A%22chart%22%2C%22utm_term%22%3A%22${name}%22%2C%22page-uri%22%3A%22cryptologia.net%2Fuser%2Ftraderoom%22%7D`}
                                                                        style={{ "width": "100%", "height": "100%", "margin": "0px !important", "padding": "0px !important" }}>
                                                                    </iframe>
                                                                </div>
                                                            </div>
                                                            <div className="tradingview-widget-copyright" style={{ "width": "100% " }}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                        {
                                            (picked === '') && (
                                                <div className="col l3 s12 m3">
                                                    <div>
                                                        <section>
                                                            <form onSubmit={handleSubmit}>
                                                                <div className="hide-on-large-only"></div>
                                                                <div className="d-col-row spacex-2">
                                                                    <div className="flex-1 text-center">
                                                                        <label className='text-small'>Amount</label>
                                                                        <input type="number" className="form-control" value={amount} onChange={(e) => setAmount(e.target.value)} />
                                                                    </div>
                                                                    <div className="flex-1 text-center">
                                                                        <label className='text-small'>Leverage</label>
                                                                        <input type="number" className="form-control" max="50" value={leverage} onChange={(e) => setLeverage(e.target.value)} />
                                                                    </div>
                                                                    <div className="flex-1 text-center">
                                                                        <label className='text-small'>Time (Minutes)</label>
                                                                        <input type="number" className="form-control" min="2" max="2880" value={time} onChange={(e) => setTime(e.target.value)} />
                                                                    </div>
                                                                </div>
                                                                <div className="hide-on-med-and-down"></div>
                                                                {
                                                                    isLoading ? (
                                                                        <div className='flex justify-center items-center'>
                                                                            <InfinitySpin
                                                                                visible={true}
                                                                                width="200"
                                                                                color="#205fb0"
                                                                                ariaLabel="infinity-spin-loading"
                                                                            />
                                                                        </div>

                                                                    ) : (
                                                                        <div className='hr-row spacex-2 mt-2'>
                                                                            <div className='col l6 s6'>
                                                                                <button type="submit" data-type="buy" className="btn btn-full text-white" style={{ backgroundColor: "green" }}>Buy</button>
                                                                            </div>
                                                                            <div className='col l6 s6'>
                                                                                <button type="submit" data-type="sell" className="btn btn-full text-white" style={{ backgroundColor: "red" }}>Sell</button>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                                <ToastContainer />
                                                            </form>
                                                        </section>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div >
                            </div ><br /><br />

                            <div className="fixed-footer bg hide-on-large-only">
                                <ul className="tabs">
                                    <li className="tab">
                                        <Link title="Home" to="/user">
                                            <HomeOutline />
                                            <span className="hide-on-small-only">Home</span>
                                        </Link>
                                    </li>

                                    <li className="tab">
                                        <Link title="Trade Room" className={(picked === '') ? "active" : ""} to="#" onClick={() => {
                                            setPicked('');
                                        }}>
                                            <StorefrontOutline />
                                            <span className="hide-on-small-only">Trade Room</span>
                                        </Link>
                                    </li>

                                    <li className="tab">
                                        <Link title="Open Trades" className={(picked === 'open') ? "active" : ""} to="#" onClick={() => {
                                            setGen(openTrades);
                                            setPicked('open');
                                        }}>
                                            <HourglassOutline />
                                            <span className="hide-on-small-only">Open Trades</span>
                                        </Link>
                                    </li>

                                    <li className="tab">
                                        <Link title="Closed Trades" className={(picked === 'closed') ? "active" : ""} to="#" onClick={() => {
                                            setGen(closedTrades);
                                            setPicked('closed');
                                        }}>
                                            <HourglassSharp />
                                            <span className="hide-on-small-only">Closed Trades</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div >
                    </div>
                    {/* added here for  */}
                    {/* <div style={{height:"45px"}}></div> */}
                </div>
            </div>
        </>
    )
}

export default TradingRoom