import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { ArrowDownOutline, ArrowUpOutline, CardOutline, EyeOffSharp, EyeSharp } from 'react-ionicons';
import Coinprices from '../components/coinprices';
import BottomNav from '../components/bottomNav';
import { useAuth } from '../authentication/userAuth';
import { LineWave } from 'react-loader-spinner';

const Index = () => {
    const { user, mysettings, settings, refreshUserData } = useAuth();

    const [open, setOpen] = useState(settings.hideBalance);
    const [coins, setCoins] = useState([]);
    const [bal, setBal] = useState(0);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const getUser = async () => {
            const resp = await fetch('https://copywavex.com/api/user/profile', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({ id: user.owner_id })
            });
            const response = await resp.json();
            refreshUserData(response.user);
            setBal(response.balance);

        }
        getUser();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.owner_id]);

    useEffect(() => {
        const getCoins = async () => {
            const resp = await fetch('https://api.coincap.io/v2/assets', {
                method: "GET"
            });
            const response = await resp.json();
            setCoins(response.data);
        }
        getCoins();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user.owner_id]);

    const toggleShowBalance = () => {
        setOpen(!open);
        mysettings({
            hideBalance: !(settings.hideBalance)
        });
    }

    return (
        <>
            <div className="d-col content mb-32 w-600 mx-auto">
                <div className='my-4'>
                    <div className='hr-row px-2'>
                        <div className='text-small'>
                            Available Balance
                        </div>
                        <Link to='#' onClick={() => toggleShowBalance()} className='ml-1'>
                            {
                                open ? (
                                    <EyeOffSharp />
                                ) : (
                                    <EyeSharp />
                                )
                            }
                        </Link>
                    </div>
                    <div className='text-left'>
                        {
                            settings.hideBalance ? (
                                <h2 className="px-2">****</h2>
                            ) : (
                                <h2 className="px-2">${Intl.NumberFormat('en-US').format(bal)}</h2>
                            )
                        }
                    </div>

                </div>

                <Coinprices coin={'BTC'} height={200} />

                <div className="bb-1 mt-4">
                    <div className="hr-row justify-around">
                        <Link className="d-col align-center my-2" to='/user/deposits'>
                            <div className="centered circle-40-full bg-grey" >
                                <ArrowUpOutline />
                            </div>
                            <div className="text-mid">
                                Send
                            </div>
                        </Link>
                        <Link className="d-col align-center my-2" to='/user/deposits'>
                            <div className="centered circle-40-full bg-grey">
                                <ArrowDownOutline />
                            </div>
                            <div className="text-mid">
                                Receive
                            </div>
                        </Link>
                        <Link className="d-col align-center my-2" to={'/buy/crypto'}>
                            <div className="centered circle-40-full bg-grey">
                                <CardOutline />
                            </div>
                            <div className="text-mid">
                                Buy
                            </div>
                        </Link>
                    </div>
                    <div class="table-head pr-2">
                        <div class="thead">Name</div>
                        <div class="w-full hr-row spacex-2 ml-auto text-right justify-right">
                            <div class="thead">Last Price</div>
                            <div class="thead" style={{ width: "80px" }}>24h chg %</div>
                        </div>
                    </div>
                </div>

                <div className="table-div scroll-y d-col flex-1">
                    <div class="table-responsive">
                        <div class="table table-hover">
                            <div class="table-body mb-4">
                                {
                                    (coins.length > 0) ? (
                                        coins?.map((data, index) => (
                                            <div class="table-row pr-2" key={index}>
                                                <div class="table-data">
                                                    {data.name}
                                                </div>
                                                <div class="w-full hr-row spacex-2 ml-auto text-right justify-right">
                                                    <div class="table-data">
                                                        <p class="text-bold">{Number(data.priceUsd).toFixed(2)}</p>
                                                        <p class="text-bold-400 text-small">${Number(data.vwap24Hr).toFixed(2)}</p>
                                                    </div>
                                                    <div class="table-data">
                                                        {
                                                            (Number(data.changePercent24Hr) > 0) ? (
                                                                <button class="butn butn-green">
                                                                    +{Number(data.changePercent24Hr).toFixed(2)}%
                                                                </button>
                                                            ) : (
                                                                <button class="butn butn-red">
                                                                    {Number(data.changePercent24Hr).toFixed(2)}%
                                                                </button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className='table-row centered'>
                                            <LineWave
                                                visible={true}
                                                height="100"
                                                width="100"
                                                color="#205fb0"
                                                ariaLabel="line-wave-loading"
                                                wrapperStyle={{}}
                                                wrapperClass=""
                                                firstLineColor=""
                                                middleLineColor=""
                                                lastLineColor=""
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    <div style={{ height: "50px" }}></div>
                </div>
            </div>
            <BottomNav home='active' />
        </>
    )
}
export default Index;