import React from 'react'
import { generatePath, useNavigate } from 'react-router-dom';
import Fft from './fft';
import { Star } from 'react-ionicons';

const IndexPrices = ({ data, addToWish, currentUser, mrkt, relo, fr, myWatch }) => {
    const navigate = useNavigate();

    const Wishlist = async () => {
        if (addToWish) {
            let mywish = {
                icon: data.icon,
                name: data.name,
                tradingview: data.tradingview,
                price_usd: data.price_usd,
                userid: currentUser,
                market_id: data.id
            }

            const dd = await fetch('https://copywavex.com/api/user/trading/wishlist/add', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify(mywish)
            })

            const resp = await dd.json();
            if (resp.status === 200) {
                relo(mrkt);
            }
        }
        return
    }

    const RemoveWishlist = async () => {
        let mywish;
        if(addToWish) {
            mywish = {
                userid: currentUser,
                market_id: data.id
            }
        }else{
            mywish = {
                userid: currentUser,
                market_id: data.market_id
            }
        }

        const dd = await fetch('https://copywavex.com/api/user/trading/wishlist/remove', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(mywish)
        })

        const resp = await dd.json();
        if (resp.status === 200) {
            relo(mrkt);
        } 
        return
    }

    const nav = () => {
        if (addToWish) {
            navigate(generatePath('/user/trading/room/:market/:name', {
                name: data.tradingview,
                market: mrkt
            }));
        }
    }

    return (
        <>
            <li className="card shadow bg-white my-2 curve-5 d-col justify-center" style={{height:"80px"}}>
                <div className="hr-row justify-between">
                    <div className='row' onClick={nav}>
                        <img src={data.icon} style={{ "width": "30px", "height": "30px", marginRight:"5px" }} alt={data.name} />
                        <div className="mx-2">{data.tradingview}<br />{data.name}</div>
                        <div>{' '}</div>
                    </div>
                    {
                        fr ? (
                            <div className="coll l1 pointer" style={{ "paddingTop": "10px", textAlign: 'right' }} onClick={RemoveWishlist}>
                                <Star color={'#6648fe'}/>
                            </div>
                        ) : (
                            <Fft myWatch={myWatch} id={data.id} Wishlist={Wishlist} removeWishlist={RemoveWishlist}/>
                        )
                    }
                </div>
            </li>
        </>
    )
}

export default IndexPrices