import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import { useAuth } from '../../authentication/userAuth';
import { InfinitySpin } from 'react-loader-spinner';
import { ImageOutline } from 'react-ionicons';
import { ThemeContext } from '../../context';

const UpdatePhoto = () => {
    const { theme } = useContext(ThemeContext);
    const [filename, setFilename] = useState('');
    const { user, refreshUserData } = useAuth();
    const [isLoading, setIsloading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const getUser = async () => {
        const resp = await fetch('https://copywavex.com/api/user/profile', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify({ id: user.owner_id })
        });
        const response = await resp.json();
        refreshUserData(response.user);
    }

    const uploadFile = (e) => {
        setFilename(e.target.files[0].name);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsloading(true);

        try {
            const formData = new FormData();
            formData.append("id", user.owner_id);
            formData.append(`photo`, e.target.photo.files[0]);

            const resp = await fetch("https://copywavex.com/api/user/account/update/photo", {
                method: 'POST',
                body: formData,
            });

            const response = await resp.json();

            if (response.status === 200) {
                toast(response.message);
                setIsloading(false);
                getUser();
                setTimeout(() => {
                    navigate('/user/profile');
                }, 2000);
            } else {
                toast.error(response.message);
                setIsloading(false);
            }

        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    return (
        <>
            <div className="content d-col">
                <div className="card bg-grey">
                    <h2 className='text-center'>Update Photo</h2><br />
                    <div className='centered'>
                        {
                            (user.profilephoto === 'human.png') ? (
                                <div className='circle-80-full pos-rel centered bg-grey mx-2'>
                                    <h3 className='text-black text-bold'>{user.fullname.split(' ')[0][0]}{user.fullname.split(' ')[1][0]}</h3>
                                </div>
                            ) : (
                                <img src={`https://copywavex.com/uploads/${user?.profilephoto}`} className="img-cover circle-80-full pos-rel centered bg-grey mx-2" alt={user.fullname} />
                            )
                        }
                    </div>
                    <div className="center">
                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <label htmlFor='photo' className='hr-row spacex-2' style={{ width: '100px', fontSize: "x-small", cursor: "pointer" }}>
                                        <ImageOutline /> Click here
                                    </label>
                                    <input
                                        type="file"
                                        accept=".jpg,.png,.jpeg"
                                        id="photo"
                                        name="photo"
                                        className='form-control'
                                        onChange={uploadFile}
                                        style={{ visibility: "hidden", display: "none" }}
                                        required
                                    />
                                    <input className='form-control' type="text" defaultValue={filename} readOnly />
                                </div>
                            </div>
                            {
                                isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>

                                ) : (
                                    <div className="input-field">
                                        <button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Update</button>
                                    </div>
                                )
                            }
                        </form>
                        <ToastContainer />
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdatePhoto