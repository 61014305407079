import React, { useContext } from 'react'
import { HomeOutline, PeopleOutline, TrendingUpOutline, WalletOutline } from 'react-ionicons'
import { Link } from 'react-router-dom'
import { ThemeContext } from '../context';

const BottomNav = ({ home, deposits, withdraws, copyTraders }) => {
    const { theme } = useContext(ThemeContext);
    return (
       <div className={(theme === 'dark')?'pos-fixed bg-white px-2 py-2 w-full bg-gren':'pos-fixed bg-white px-2 py-2 w-full bg-gren-light'} style={{ height:"50px", bottom:"0", width:"100%", zIndex:"3"}}>
            <ul className='hr-row justify-between w-400 mx-auto'>
                <li>
                    <Link to={'/user'} className={home?'centered bg-gray curve-5 p-1':'centered'}>
                        <HomeOutline  color={"#fff"}/>
                        {
                            (home === 'active')&&(
                                <span className='ml-1 text-white'>Home</span>
                            )
                        } 
                    </Link>
                </li>
                <li>
                    <Link to={'/user/deposits/'} className={deposits?'centered bg-gray curve-5 p-1':'centered'} >
                        <WalletOutline  color={"#fff"}/>
                        {
                            (deposits === 'active')&&(
                                <span className='ml-1 text-white'> Wallet</span>
                            )
                        } 
                    </Link>
                </li>
                <li>
                    <Link to={'/user/trading/markets'} className={withdraws?'centered bg-gray curve-5 p-1':'centered'} >
                        <TrendingUpOutline  color={"#fff"}/>
                        {
                            (withdraws === 'active')&&(
                                <span className='ml-1 text-white'> Trading</span>
                            )
                        } 
                    </Link>
                </li>
                <li>
                    <Link to={'/user/copy-traders'} className={copyTraders?'centered bg-gray curve-5 p-1':'centered'} >
                        <PeopleOutline color={"#fff"}/>
                        {
                            (copyTraders === 'active')&&(
                                <span className='ml-1 text-white'> Copy Traders</span>
                            )
                        } 
                    </Link>
                </li>
            </ul>
        </div>
    )
}

export default BottomNav
