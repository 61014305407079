import Aos from 'aos';
import React, { useContext, useEffect } from 'react'
import { CashSharp, CheckboxSharp, CopySharp, ExpandSharp, MoonSharp, PhoneLandscapeSharp, ShieldCheckmarkSharp, SunnySharp, TimeSharp } from 'react-ionicons';
import 'aos/dist/aos.css'
import Trading1 from './components/trading1';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useAuth } from '../authentication/userAuth';
import { ThemeContext } from '../context';

const LandingPage = () => {
  const { theme, setTheme } = useContext(ThemeContext);
  const { mysettings } = useAuth();

  useEffect(() => {
    Aos.init();
    Aos.refresh();
  }, []);

  useEffect(() => {
    document.body.classList.remove('remove-tidio');
  }, []);

  const setSiteTheme = () => {
    if (theme === 'light') {
      setTheme('dark');
    } else {
      setTheme('light');
    }
    mysettings({
      theme: theme
    });
  }

  return (
    <div className={(theme === 'dark') ? 'd-col bg-blue bg-geb' : 'd-col'} style={{ overflowX: "hidden" }} >
      <section className={(theme === "light") ? "hero bg-white jk" : "hero bg-dark-blue-2"} style={{ height: "85vh", position: "relative" }}>
        <div className='hr-row justify-between px-2 pos-fixed' style={{ height: "60px", top: "0", left: "0", width: "100%", zIndex: "99" }}>
          <h2 className={(theme === 'light') ? 'text-d-blue-2' : 'text-brown'}>Copywavex</h2>
          <div className='hr-row spacex-2'>
            <div onClick={setSiteTheme} className="pointer">
              {
                (theme === 'light') ? (
                  <MoonSharp color={"#03162e"} />
                ) : (
                  <SunnySharp color={"#fff"} />
                )
              }
            </div>
            <Link to={'/login'} className={(theme === 'light') ? 'ml-2 btn bg-dark-blue shadow text-white px-2' : 'ml-2 btn bg-blue shadow text-white px-2'}>
              Sign in
            </Link>
          </div>
        </div>
        {
          (theme === 'light') ? (
            <video
              src='assets/header1.mp4'
              className='landing-vid' muted autoPlay loop>
            </video>
          ) : (
            <video
              src='assets/header.mp4'
              className='landing-vid' muted autoPlay loop>
            </video>
          )
        }

        <div className='card headerm' data-aos="zoom-out" data-aos-duration="2000">
          <Carousel
            autoPlay={true}
            infiniteLoop={true}
            showThumbs={false}
            interval={8000}
            showIndicators={false}
            showStatus={false}
            showArrows={false}
          >
            <div>
              <h1>Unlock Your Potential with Crypto and Copy Trading</h1>
              <p className={(theme === 'light') ? "mb-8 text-d-blue" : "mb-8 text-brown"}>Join our platform to trade cryptocurrencies and copy top traders.</p>
            </div>
            <div>
              <h1>Instant deposit, Fast withdrawal, no hidden charges </h1>
              <p className={(theme === 'light') ? "mb-8 text-d-blue" : "mb-8 text-brown"}>Join our platform to trade cryptocurrencies and copy top traders.</p>
            </div>
            <div>
              <h1>List of professional traders to copy their trades</h1>
              <p className={(theme === 'light') ? "mb-8 text-d-blue" : "mb-8 text-brown"}>Join our platform to trade cryptocurrencies and copy top traders.</p>
            </div>

          </Carousel>

          <Link to='/register' className='mt-4' style={{ position: "relative", zIndex: "4" }}>
            {/* <button className='bg-blue curve-5 shadow' style={{ boxShadow: "0 0 5px #fff" }}>Get Started Now</button> */}
            <img className="image-45" src="assets/663ad627c7d2570b7fac36a2/663ad8a2a2cb4f6423297c50_Frame%201736.png" alt=""
              sizes="(max-width: 479px) 86vw, (max-width: 767px) 62vw, (max-width: 991px) 50vw, 500px"
              data-w-id="2e5d123a-85ea-c992-940b-d660469d242a" loading="lazy"
              srcset="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ad8a2a2cb4f6423297c50_Frame%201736-p-500.png 500w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ad8a2a2cb4f6423297c50_Frame%201736-p-800.png 800w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ad8a2a2cb4f6423297c50_Frame%201736-p-1080.png 1080w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ad8a2a2cb4f6423297c50_Frame%201736.png 1364w" />

            <div data-aos="" className={(theme === "light") ? "glowing-wrapper glowing-wrapper-active bg-dark-blue" : "glowing-wrapper glowing-wrapper-active"}>
              <div className="glowing-wrapper-animations">
                <div className={(theme === "light") ? "glowing-wrapper-glow glw-light" : "glowing-wrapper-glow glw-black"}></div>
                <div className="glowing-wrapper-mask-wrapper">
                  <div className="glowing-wrapper-mask"></div>
                </div>
              </div>
              <div className="glowing-wrapper-borders-masker">
                <div className="glowing-wrapper-borders"></div>
              </div>
              <Link to="/register" className="glowing-wrapper-button w-inline-block">
                <div className={(theme === 'light') ? "button-text text-white" : "button-text"}>Get started</div>
              </Link>
            </div>
          </Link>
        </div>

      </section>

      <Trading1 />
      <section className={(theme === 'light') ? "px-2" : "px-2 bg-geb"}>
        <br /><br /><br />
        <div data-aos="fade-left" className={(theme === 'light') ? 'heading-text-1 text-d-blue-2' : 'heading-text-1 text-white'} >Why Choose us?</div>
        <div className='d-col card'>
          <div className='d-row-col spacex-2'>
            <div className="benefit shadow col l4 s12" data-aos="fade-up" >
              {
                (theme === 'light') ? (
                  <CashSharp color={"#000"} />
                ) : (
                  <CashSharp color={"#fff"} />
                )
              }
              <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                No hidden charges
              </p>
            </div>
            <div className="benefit shadow col l4 s12" data-aos="fade-down" >
              {
                (theme === 'light') ? (
                  <ShieldCheckmarkSharp color={"#000"} />
                ) : (
                  <ShieldCheckmarkSharp color={"#fff"} />
                )
              }
              <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                Secure Transactions
              </p>
            </div>
            <div className="benefit shadow col l4 s12" data-aos="fade-up" >
              {
                (theme === 'light') ? (
                  <TimeSharp color={"#000"} />
                ) : (
                  <TimeSharp color={"#fff"} />
                )
              }
              <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"} >
                Real-Time Data
              </p>
            </div>
          </div>
          <div className='d-row-col spacex-2'>
            <div className="benefit shadow col l4 s12" data-aos="fade-left" >
              {
                (theme === 'light') ? (
                  <PhoneLandscapeSharp color={"#000"} />
                ) : (
                  <PhoneLandscapeSharp color={"#fff"} />
                )
              }
              <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                Easy-to-Use Interface
              </p>
            </div>
            <div className="benefit shadow col l4 s12" data-aos="fade-up" >
              {
                (theme === 'light') ? (
                  <ExpandSharp color={"#000"} />
                ) : (
                  <ExpandSharp color={"#fff"} />
                )
              }
              <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                Access to Expert Traders
              </p>
            </div>
            <div className="benefit shadow col l4 s12" data-aos="fade-right" >
              {
                (theme === 'light') ? (
                  <CopySharp color={"#000"} />
                ) : (
                  <CopySharp color={"#fff"} />
                )
              }
              <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                Automated Copy Trading
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className={(theme === 'light') ? "px-2" : "px-2 bg-geb"}>
        <br /><br /><br />
        <div className="div-block-7 " data-aos="fade-right" >
          {/* <div className={(theme === 'light')?"text-block-25 text-d-blue" :"text-block-25 text-white"}>THE&nbsp;FIRST</div> */}
          <div className={(theme === 'light') ? "heading-text-1 text-d-blue" : "heading-text-1 text-white"}>It's all <span className="text-span-8">automatic</span>. Like <span className="text-span-7">magic</span>.
          </div>
        </div>
        <br /><br />
        <div className='d-row-col spacex-2'>
          <div data-aos="fade-up" data-aos-offset="50" className="trio-cards card-1 bg-white-f">
            <div className="text-block-28">1</div>
            <div className="text-block-26">Open a brokerage account</div>
            <div className="text-block-27">WE have an SEC registered broker dealer, just like Fidelity or Schwab</div>
          </div>

          <div data-aos="fade-up" data-aos-offset="150" className="trio-cards card-1 bg-white-f">
            <div className="text-block-28">2</div>
            <div className="text-block-26">Copy a portfolio just like buying a stock</div>
            <div className="text-block-27">Copy politicians, hedge funds, or top traders on copywavex</div>
          </div>

          <div data-aos="fade-up" data-aos-offset="300" className="trio-cards card-1 bg-white-f">
            <div className="text-block-28">3</div>
            <div className="text-block-26">Automatically follow all future trades</div>
            <div className="text-block-27">Everything the portfolio creator buys and sells you do too. Same time, same price.</div>
          </div>
        </div><br /><br /><br />
        <div className='card'>
          <button className="button section-button btn-full btn-curve" data-aos="fade-up">
            EXPERIENCE MAGIC
          </button>
        </div>
      </section>

      <section className='px-2 d-col card'>
        <br /><br /><br />
        <div className="div-block-7" data-aos="fade-left">
          <div className={(theme === 'light') ? "text-block-25 text-d-blue" : "text-block-25 text-white"}>EXCLUSIVE ACCESS</div>
          <div className={(theme === 'light') ? "heading-text-1 text-d-blue" : "heading-text-1 text-white"}>Copy politicians, hedge funds...</div>
        </div>
        <div className='d-row-col spacex-2' >
          <div id="" data-aos="fade-up" data-aos-offset="50" className="trio-cards-portfolios bill-ackman" >
            <div className="text-block-29 ">Bill Ackman</div>
            <div className="text-block-30">+38.31%</div>
            <div className="text-block-31">All Time (Inception: 9/17/2023)<sup>1</sup>
            </div>
          </div>
          <div data-aos="fade-up" data-aos-offset="150" className="trio-cards-portfolios nancy-pelosi" >
            <div className="text-block-29">Nancy Pelosi</div>
            <div className="text-block-30">+68.01%</div>
            <div className="text-block-31">
              All Time (Inception: 9/17/2023)<sup>1</sup>
            </div>
          </div>

          <div data-aos="fade-up" data-aos-offset="300" className="trio-cards-portfolios jerome-powell " >
            <div className="text-block-29">Jerome Powell </div>
            <div className="text-block-30">+18.81%</div>
            <div className="text-block-31">All Time (Inception: 9/17/2023)<sup>1</sup>
            </div>
          </div>
        </div>
      </section>

      <section className={(theme === 'light') ? "my-4 how-it-works px-2 " : "my-4 how-it-works px-2 bg-geb"}>
        <br /><br /><br />
        <div className="div-block-7" data-aos="fade-right">
          <div className={(theme === 'light') ? "text-block-25 text-d-blue" : "text-block-25 text-white"}>STEPS</div>
          <div className={(theme === 'light') ? "heading-text-1 text-d-blue" : "heading-text-1 text-white"}>How It Works</div>
        </div>
        <div className='d-row-col spacex-2 card'>
          <div className='shadow col l4 s12 py-2 px-2' data-aos="fade-up"  >
            <div className={(theme === 'light') ? "step text-d-blue" : "step text-white"}>Step 1:</div>
            <div className='d-row-col spacex-2'>
              <div className="s12">
                {
                  (theme === 'light') ? (
                    <CheckboxSharp color={"#000"} />
                  ) : (
                    <CheckboxSharp color={"#fff"} />
                  )
                }
                <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                  Sign Up - Create your free account
                </p>
              </div>
            </div>
          </div>

          <div className='shadow col l4 s12 py-2 px-2' data-aos="fade-up" >
            <div className={(theme === 'light') ? "step text-d-blue" : "step text-white"}>Step 2:</div>
            <div className='d-row-col spacex-2'>
              <div className="s12">
                {
                  (theme === 'light') ? (
                    <CheckboxSharp color={"#000"} />
                  ) : (
                    <CheckboxSharp color={"#fff"} />
                  )
                }
                <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                  Choose a Trader - Browse and select top traders to copy.
                </p>
              </div>
            </div>
          </div>

          <div className='shadow col l4 s12 py-2 px-2' data-aos="fade-up">
            <div className={(theme === 'light') ? "step text-d-blue" : "step text-white"}>Step 3:</div>
            <div className='d-row-col spacex-2'>
              <div className="s12">
                {
                  (theme === 'light') ? (
                    <CheckboxSharp color={"#000"} />
                  ) : (
                    <CheckboxSharp color={"#fff"} />
                  )
                }
                <p className={(theme === 'light') ? "text-center text-d-blue" : "text-center text-white"}>
                  Start Trading - Automatically copy trades and watch your investments grow.
                </p>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className={(theme === 'light') ? "section-13 px-2" : "section-13 px-2 bg-geb"}>
        <br /><br /><br />
        <div className="div-block-7" data-aos="fade-left">
          <div className={(theme === 'light') ? "text-block-25 text-d-blue" : "text-block-25 text-white"}>COMPETE</div>
          <div className={(theme === 'light') ? "heading-text-1 text-d-blue" : "heading-text-1 text-white"}>...or create your own portfolio and compete</div>
        </div>
        <div className="div-block-8 friends-layout">

          <div data-aos="fade-up" className="div-block-14  bg-white-f">
            <div id="w-node-_88de5aa0-086c-49e1-720f-4707b53b3401-7fac36a1" className="text-block-36 text-white">Copywavex Creator</div>
            <div className="text-block-37 text-ddk ">"Joining Copywavex has been an incredible journey, from my humble beginnings as just a Twitter user sharing unusual option activity, to now overseeing a portfolio exceeding $350k in capital and attracting over 200 copiers."</div>
            <div className="columns-3 w-row">
              <div className="column-6 w-col w-col-3 w-col-stack">
                <img src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663e41697018186a833b684e_kian.png" loading="lazy" alt="" className="image-57" /></div>
              <div className="column-9 w-col w-col-9 w-col-stack">
                <div className="text-block-33 text-ddk">Kian Saidi</div>
                <div className="text-block-34 text-ddk">Owl's Investing Room Founder<br />40k followers on X / Twitter <sup>2</sup></div>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="div-block-14 bg-white-f">
            <div id="w-node-ab76f6ba-f2b3-c558-2c0b-ad8fe976a618-7fac36a1" className="text-block-36 text-white">Copywavex Creator</div>
            <div className="text-block-37 text-ddk">"Copywavex is democratizing the professional money management industry through transparency, accountability, and cutting edge technology in a way that is revolutionizing the way individuals will invest tomorrow."</div>
            <div className="w-row">
              <div className="column-6 w-col w-col-3 w-col-stack w-col-small-small-stack">
                <img src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663e402e2d9bab11512950d7_1124120SPK_350x350.jpg" loading="lazy" alt="" className="image-57" /></div>
              <div className="column-7 w-col w-col-9 w-col-stack w-col-small-small-stack">
                <div className="text-block-33 text-ddk">Lawrence Fuller</div>
                <div className="text-block-34 text-ddk">SeekingAlpha Top Writer<br />Fuller Management Principal <sup>2</sup></div>
              </div>
            </div>
          </div>

          <div data-aos="fade-up" className="div-block-14 bg-white-f">
            <div id="w-node-_29702291-f872-1a69-3c44-14c32ffcc7a8-7fac36a1" className="text-block-36 text-white">Copywavex Creator</div>
            <div className="text-block-37 text-ddk">"Copywavex allows creators to launch strategies similar to an ETF, without the startup costs, in a marketplace with thousands of potential investors"</div>
            <div className="w-row">
              <div className="column-6 w-col w-col-3 w-col-stack">
                <img src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663e4129ddc957bb606047df_1549491240597.jpeg" loading="lazy" alt="" className="image-57" /></div>
              <div className="column-8 w-col w-col-9 w-col-stack">
                <div className="text-block-33 text-ddk">Andrew Ver Planck</div>
                <div className="text-block-34 text-ddk">McKay Shields, Former CIO<br />Quant Hedge Fund Manager <sup>2</sup></div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-13 bg-white-f">
        <div className="div-block-7">
          <br /><br /><br />
          <div className="text-block-25" data-aos="fade-right">TRUSTED</div>
          <div className="heading-text-1" data-aos="fade-left">Join 110k+ other people who’ve downloaded Copywavex</div>
          <div className="w-layout-grid grid-feature-2">
            <div data-aos="fade-up" className="feature">
              <div className="div-block-16">
                <img src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663f7f3ca87eb50b72f6b53c_%F4%80%86%BF.png" loading="lazy" alt="" className="image-61" />
              </div>
              <h5 className="heading-feature">Top Security</h5>
              <p className="paragraph-feature">Your accounts are secured via AES grade bank grade encryption and security.</p>
            </div>
            <div data-aos="fade-up" className="feature">
              <div className="div-block-16">
                <img src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663f7f3cd43ef396ff8e1cd0_%F4%80%87%BB.png" loading="lazy" alt="" className="image-61" />
              </div>
              <h5 className="heading-feature">SIPC&nbsp;Insured</h5>
              <p className="paragraph-feature">Your accounts are insured up to $500,000 by the Securities Investor Protection Corporation, or SIPC.</p>
            </div>
            <div data-aos="fade-up" className="feature">
              <div className="div-block-16">
                <img src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663f7f3c8e43478ef7cc3571_%F4%80%9E%9A.png" loading="lazy" alt="" className="image-61" />
              </div>
              <h5 className="heading-feature">FINRA&nbsp;&amp;&nbsp;SEC&nbsp;Regulated</h5>
              <p className="paragraph-feature">Copywavex's family of companies includes a Broker Dealer, Member FINRA and a SEC Registered Investment Advisor.</p>
            </div>
          </div>
        </div>
      </section>

      <section className={(theme === 'light') ? "section-13 " : "section-13  bg-geb"}>
        <div className="div-block-7">
          <div className={(theme === 'light') ? "text-block-25 text-d-blue" : "text-block-25 text-white"} data-aos="fade-up">INSTITUTIONALIZED</div>
          <div className={(theme === 'light') ? "heading-text-1 text-d-blue" : "heading-text-1 text-white"} data-aos="fade-up">Backed by top investors</div>
          <div className='py-4' style={{ backgroundColor: "#131415" }}>
            <img className="image-55"
              src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663c3c24b9904a536d1d50f2_Frame%201769.png" alt=""
              sizes="100vw"
              data-aos="fade-up" loading="lazy"
              srcset="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663c3c24b9904a536d1d50f2_Frame%201769-p-500.png 500w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663c3c24b9904a536d1d50f2_Frame%201769-p-800.png 800w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663c3c24b9904a536d1d50f2_Frame%201769-p-1080.png 1080w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663c3c24b9904a536d1d50f2_Frame%201769.png 1188w" />
            <img className="image-50"
              src="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_Copywavex%20investor%20list.png" alt=""
              sizes="(max-width: 479px) 100vw, (max-width: 767px) 95vw, (max-width: 991px) 96vw, 900px"
              data-aos="fade-up"
              loading="lazy"
              srcset="https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_Copywavex%20investor%20list-p-500.png 500w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_dub%20investor%20list-p-800.png 800w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_dub%20investor%20list-p-1080.png 1080w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_dub%20investor%20list-p-1600.png 1600w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_dub%20investor%20list-p-2000.png 2000w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_dub%20investor%20list-p-2600.png 2600w, https://cdn.prod.website-files.com/663ad627c7d2570b7fac36a2/663ae98b286a0561b8efba51_dub%20investor%20list.png 2796w" />
          </div>
        </div>
      </section>

      {/* <section className="section-9">
        <div className="text-block-25">FAQ</div>
        <div className="heading-text-1">Common Questions</div>
        <div data-aos="27ce4db6-9039-609d-5283-b2f32bc8ff02" style={{ "opacity": "1" }} className="cloneable-container-default">
          <div className="accordion-item-wrapper---brix preview-page">
            <div data-aos="27ce4db6-9039-609d-5283-b2f32bc8ff04" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix"><span className="text-span">What does it mean to copy someone?</span></div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Copy is an innovative feature on Copywavex that enables you to mirror the investment portfolio created by another user automatically. When the portfolio creator trades, your account will trade at the same time and at the same price as the creator’s account. This ensures your investment remains aligned with the portfolio in real time.</p>
              </div>
            </div>
            <div data-aos="27ce4db6-9039-609d-5283-b2f32bc8ff0f" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">Why should I copy someone?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">If you like a portfolio, you can copy it to capture that portfolio’s strategy in your own account, allowing you to benefit from a creator’s insights without having to replicate their strategy yourself. <br /><br />Some investors might not know where to start, and having the ability to copy different strategies and see how they perform can be a good first step. Other users might use copy trading as a way to learn and build off of strategies we have on the app.</p>
              </div>
            </div>
            <div data-aos="27ce4db6-9039-609d-5283-b2f32bc8ff1a" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">Who can I copy?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Right now, you can copy the portfolio of anyone who makes a portfolio on Copywavex! Just search up their portfolio by the name or ticker.<br />‍<br />You can also copy portfolios created by @CopywavexCapital, our in-house registered investment advisor and portfolios of various select creators who have elected to share their portfolios on Copywavex. Creators on Copywavex come from various backgrounds. Some may have professionally managed billions of dollars, while others may have gained recognition for their stock-picking ability through social media.<br /></p>
              </div>
            </div>
            <div data-aos="27ce4db6-9039-609d-5283-b2f32bc8ff25" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">Can people copy my portfolio?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Anyone can make a portfolio and get copied a long as they make their portfolio public, which requires only a minimum $1,000 personal investment in their own portfolio from the creator</p>
              </div>
            </div>
            <div data-aos="4c326474-384a-1822-cb6e-b2e21a1f7c5f" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">What does Copywavex cost?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Copywavex is a commission-free investment app. However, Copywavex will soon be offering soon a freemium version of the platfom where users will be allowed to copy up to one portfolio for free.&nbsp;To copy more than one portfolio, to access portfolio creation, and other premium features, users will be required to subscribe for $9.99 a month. </p>
              </div>
            </div>
            <div data-aos="bfe5264f-9e9f-1bf4-dcce-4394d093dbad" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">How do we differ from other investing apps?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Copywavex is centered around Copying, or commonly known as copy-trading. Unlike other trading apps, Copywavex believes the future of trading revolves around investing in people and their ideas. The days of picking individual stocks and not knowing when to sell or double down are in the past. We are building a community where people can share strategies, track records, and knowledge.</p>
              </div>
            </div>
            <div data-aos="437f5dab-230a-3d69-3de3-1a444147f80d" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">Are there any minimums to invest?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">There is a $100 deposit minimum as well as differing minimums for individual portfolios that you copy.</p>
              </div>
            </div>
            <div data-aos="e79bee26-8134-ce67-3136-7617fa1561ba" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">How long do I have to wait until my money is deposited in the app?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Deposits up to $1,000 are instantly available for investment. Larger deposits typically take 1-2 days to become fully available.</p>
              </div>
            </div>
            <div data-aos="e52d1381-4984-b300-48cd-55a0a91f91ea" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">Are you regulated?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Copywavex owns two subsidiaries, (<a href="/6cf5578dd98048cbb741b89246e3e1bb?pvs=25"><strong>DASTA Financial</strong></a>) a broker dealer registered with the SEC and Member FINRA &amp; SIPC, and (DASTA Investments, “Copywavex Capital”) a registered investment advisor with the SEC.</p>
              </div>
            </div>
            <div data-aos="f1ca26f9-c719-6e76-e1ac-f3c99b49e42d" style={{ borderColor: "rgba(0, 0, 0, 0)" }} className="accordion-item---brix accordion-1---brix">
              <div className="accordion-trigger---brix">
                <div className="accordion-item-title---brix">Who can register for Copywavex?</div>
                <div style={{ backgroundColor: "rgb(255, 255, 255)" }} className="accordion-arrow-wrap---brix">
                  <div className="accordion-arrow-wrapper---brix">
                    <div className="arrow-line---brix _2---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                    <div className="arrow-line---brix _1---brix" style={{ backgroundColor: "rgb(237, 174, 133)" }}></div>
                  </div>
                </div>
              </div>
              <div className="accordion-content---brix">
                <p className="accordion-paragraph---brix">Currently Copywavex is only available to US Citizens, US Greencard Holders, and US Permanent residents. We plan on making Copywavex more widely available ASAP. If you aren't currently eligble to use Copywavex and potentially early access, please reach out to support@Copywavexapp.com.</p>
              </div>
            </div>
          </div>
        </div>
      </section> */}


    </div >
  )
}

export default LandingPage
