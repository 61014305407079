import React, { useContext, useEffect, useState } from 'react'
import { useAuth } from '../../authentication/userAuth';
import { useNavigate, useParams } from 'react-router-dom';
import { CopyOutline } from 'react-ionicons';
import { InfinitySpin, LineWave } from 'react-loader-spinner';
import { toast, ToastContainer } from 'react-toastify';
import Qrcode from './qrcode';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ThemeContext } from '../../context';

const DepositStep3 = () => {
    const { coin } = useParams();
    const { theme } = useContext(ThemeContext);
    const { user } = useAuth();
    const [isLoading, setIsloading] = useState(false);
    const [isLoading2, setIsloading2] = useState(false);

    const navigate = useNavigate();

    const [paytype, setPaytype] = useState('btc');
    const [walletAddress, setwalletAddress] = useState('');

    // const [amount, setAmount] = useState(0);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    useEffect(() => {
        const getWallet = async () => {
            setIsloading2(true);
            const dd = await fetch('https://copywavex.com/api/user/wallet/address', {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    wallet: coin
                })
            });

            const resp = await dd.json();
            setwalletAddress(resp);
            setIsloading2(false);
            setPaytype(coin);
        }
        getWallet();
    }, [coin]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsloading(true);
        let data = {
            paytype,
            "amount":0,
            account: "Funding account",
            payerid: user.owner_id,
            wallet_address: walletAddress,
            type: coin
        }

        const dd = await fetch('https://copywavex.com/api/user/deposits/fund/add', {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(data)
        })

        const resp = await dd.json();
        if (resp.status === 200) {
            setIsloading(false);
            navigate(`/user/deposits/upload-proof/${resp.depositID}`);
        } else {
            setIsloading(false);
            toast.error(resp.message);
        }
    }

    return (
        <>
            <div className="content scroll-y mb-4">
                {
                    isLoading2 && (
                        <div style={{ position: "fixed", height: "100vh", width: "100%", zIndex: 999, display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <LineWave
                                visible={true}
                                height="100"
                                width="100"
                                color="#205fb0"
                                ariaLabel="line-wave-loading"
                                wrapperStyle={{}}
                                wrapperClass=""
                                firstLineColor=""
                                middleLineColor=""
                                lastLineColor=""
                            />
                        </div>
                    )
                }
                <div className='flex-1 centered scroll-y'>
                    <div className="h-full w-full d-col justify-center">
                        <div className="card bg-grey" style={{ margin: "0 auto" }}><br />
                            <form onSubmit={handleSubmit} className='px-2 scroll-y mt-2'>
                                <div className="scroll-y">
                                    {walletAddress && (
                                        <>
                                            <div className="background text-center centered">
                                                <Qrcode wallet={walletAddress} />
                                            </div><br />

                                            <div className='mt-2'>
                                                <p>Wallet Address </p>
                                                <div className="input-field" style={{ margin: "0" }}>
                                                    <div className='input-with-icon bg-grey'>
                                                        <CopyToClipboard text={walletAddress}
                                                            onCopy={() => toast.success('Copied Successfully')}>
                                                            <CopyOutline style={{ cursor: "pointer" }} />
                                                        </CopyToClipboard>
                                                        <input
                                                            type="text"
                                                            value={walletAddress}
                                                            className='form-control bg-grey'
                                                            readOnly
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='shadow px-2'>
                                                <div className='hr-row justify-between'>
                                                    <div className='d-col'>
                                                        <div className='my-2'>
                                                            <p>Minimum deposit amount</p>
                                                        </div>
                                                    </div>
                                                    <div className='d-col'>
                                                        <div className='my-2'>
                                                            <p>{user.min_deposit} {coin}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='hr-row justify-between'>
                                                    <div className='d-col'>
                                                        <div className='my-2'>
                                                            <p>Route Deposit To</p>
                                                        </div>
                                                    </div>
                                                    <div className='d-col'>
                                                        <div className='my-2'>
                                                            <p>Funding account</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='bg-grey'>
                                                <h5>Note:</h5>
                                                <p>
                                                    Please make sure that only {coin} deposit is made via this address. 
                                                    Otherwise your deposited funds will not be added to your available balance - nor will it be refunded
                                                </p>
                                            </div>
                                            <br />
                                            <div className='input-field' style={{ margin: "0" }}>
                                                {
                                                    isLoading ? (
                                                        <div className='flex justify-center items-center'>
                                                            <InfinitySpin
                                                                visible={true}
                                                                width="200"
                                                                color="#205fb0"
                                                                ariaLabel="infinity-spin-loading"
                                                            />
                                                        </div>

                                                    ) : (
                                                        <>
                                                            <div className='w-full hr-row spacex-2'>
                                                                <CopyToClipboard text={walletAddress}
                                                                    onCopy={() => toast.success('Copied Successfully')}>
                                                                    <button type="button" className={(theme === 'light') ? "btn btn-full bg-blue-light text-white" : "btn btn-full bg-blue text-white"}>Copy Address</button>
                                                                </CopyToClipboard>
                                                                <button type="submit" className={(theme === 'light') ? "btn btn-full bg-blue-light text-white" : "btn btn-full bg-blue text-white"}>Next</button>
                                                            </div>
                                                        </>
                                                    )
                                                }
                                                <ToastContainer />
                                            </div>
                                        </>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DepositStep3