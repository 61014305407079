import React, { useEffect } from 'react';
import { HomeOutline, LockClosedOutline, MailOutline, PersonCircleOutline, SettingsOutline, ShieldOutline } from 'react-ionicons'
import { Link } from 'react-router-dom'
import { useAuth } from '../authentication/userAuth'

const Profile = () => {
  const { user } = useAuth();
  useEffect(() => {
    document.body.classList.add('remove-tidio');
}, []);
  return (
    <>
      <div className="content d-col scrol-y">
        <div className='card flex-1 d-row-col spacex-2 curve-10'>
          <div className='w-half'>
            <div className='d-col centered my-2'>
              <img src={`https://copywavex.com/uploads/${user?.profilephoto}`} className="img-cover circle-80-full pos-rel centered bg-grey mx-2" alt={user.fullname} />
              <h2 className='text-center mt-2 text-black'>{user?.fullname}</h2>
            </div>
            <Link className='my-2 py-2 px-2 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/verifications' >
              <ShieldOutline />
              <div className='w-full hr-row justify-between'>
                <p>User Verification</p>
                <div className='text-right'>
                  {
                    (user?.kycVerifyStatus === "1" && user?.idVerifyStatus === "1" && user?.emailVerificationStatus === "1") ? (
                      <span className='text-green' style={{ "fontSize": "12px" }}>
                        Completed
                      </span>
                    ) : (
                      <span className='text-red' style={{ "fontSize": "12px" }}>
                        Pending
                      </span>
                    )
                  }
                </div>
              </div>
            </Link>
            <Link className='py-2 px-2 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/settings' >
              <SettingsOutline />
              <p>
                Settings
              </p>
            </Link>
          </div>
          <div className='w-half'>
            <Link className='py-2 px-2 my-4 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/updates/account'>
              <HomeOutline />
              <p>
                Update address
              </p>
            </Link>
            <Link className=' py-2 px-2 my-4 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/updates/password'>
              <LockClosedOutline />
              <p>
                Update password
              </p>
            </Link>
            <Link className=' py-2 px-2 my-4 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/updates/profile-photo'>
              <PersonCircleOutline />
              <p>
                Update proflie photo
              </p>
            </Link>
            <Link className=' py-2 px-2 my-4 bg-white curve-10 shadow hr-row w-full spacex-2' to='/user/updates/email'>
              <MailOutline />
              <p>
                Update email
              </p>
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}

export default Profile