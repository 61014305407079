import React, { useContext, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../authentication/userAuth';
import { InfinitySpin } from 'react-loader-spinner';
import { ImageOutline } from 'react-ionicons';
import { ThemeContext } from '../../context';

const UploadProof = () => {
    const { theme } = useContext(ThemeContext);
    const { id } = useParams();
    const navigate = useNavigate();
    const [filename, setFilename] = useState('');
    const { user } = useAuth();
    const [isLoading, setIsloading] = useState(false);

    useEffect(() => {
        document.body.classList.add('remove-tidio');
    }, []);

    const uploadFile = (e) => {
        setFilename(e.target.files[0].name);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsloading(true);
        // navigate('/user/deposits/list');

        try {
            const formData = new FormData();
            formData.append("uploader_id", user.owner_id);
            formData.append("deposit_id", id);
            formData.append(`files`, e.target.proof.files[0]);

            const resp = await fetch("https://copywavex.com/api/user/deposits/fund/proof", {
                method: 'POST',
                body: formData,
            });

            const response = await resp.json();

            if (response.status === 200) {
                toast("Upload successfull");
                setIsloading(false);
                setTimeout(() => {
                    navigate('/user/deposits/list');
                }, 2000);
            } else {
                toast(response.message);
                setIsloading(false);
            }
        } catch (err) {
            setIsloading(false);
            toast.error(err.message);
        }
    }

    return (
        <>
            <div className="content d-col">
                <div className="card bg-grey">
                    <h2 className="text-center">Upload Payment Proof</h2><br />
                    <div className="cardd-panel center">
                        <form encType="multipart/form-data" onSubmit={handleSubmit}>
                            <div className="input-field">
                                <div className="input-with-icon">
                                    <label htmlFor='proof' className='hr-row spacex-2' style={{ width: '100px', fontSize: "x-small", cursor: "pointer" }}>
                                        <ImageOutline /> Click here
                                    </label>
                                    <input
                                        type="file"
                                        accept=".jpg,.png,.jpeg"
                                        id="proof"
                                        name="proof"
                                        className='form-control'
                                        onChange={uploadFile}
                                        style={{ visibility: "hidden", display: "none" }}
                                        required
                                    />
                                    <input className='form-control' type="text" defaultValue={filename} readOnly />
                                </div>
                            </div>
                            {
                                isLoading ? (
                                    <div className='flex justify-center items-center'>
                                        <InfinitySpin
                                            visible={true}
                                            width="200"
                                            color="#205fb0"
                                            ariaLabel="infinity-spin-loading"
                                        />
                                    </div>

                                ) : (
                                    <div><button type="submit" className={(theme === 'light')?"btn btn-full bg-blue-light text-white":"btn btn-full bg-blue text-white"}>Submit</button></div>
                                )
                            }

                        </form>

                        <ToastContainer />
                        <br /><br />
                        {/* <div className='text-center'>
                            <Link to="/deposits/list">My Deposits</Link>
                            <br /><br />
                            <Link to="/" >My Dashboard</Link>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default UploadProof
